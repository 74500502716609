'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";

export default {
    async getPayrolls() {
        const data = {
            method: 'get',
            url: 'payrolls',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getPayrollNextNo() {
        const data = {
            method: 'get',
            url: 'payrollNextNo',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getPayroll(id) {
        const data = {
            method: 'get',
            url: 'payroll/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createPayroll(json) {
        const data = {
            method: 'post',
            url: 'payroll',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Payroll created successfully')
            return true;
        }
    },
    async confirmPayroll(id) {
        const data = {
            method: 'patch',
            url: 'payroll/status/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payroll confirmed successfully')
            window.location.reload()
        }
    },
    async deletePayroll(id) {
        const data = {
            method: 'delete',
            url: 'payroll/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payroll deleted successfully')
            router.push('/payrolls/employees')
        }
    },
    async getPayslip(id) {
        const data = {
            method: 'get',
            url: 'payslip/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getPayslipHtml(id) {
        const data = {
            method: 'get',
            url: 'payslip/' + id + '/html',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async deletePayslip(id) {
        const data = {
            method: 'delete',
            url: 'payslip/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payslip deleted successfully')
            return true;
        }
    },
    async emailPayslip(id) {
        const data = {
            method: 'get',
            url: 'payslip/email/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payslip sent to email successfully')
            router.push('/payslip/' + id)
        }
    },
    async generatePayslip(id) {
        const data = {
            method: 'get',
            url: 'payslip/generate/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async exportPayroll(json, id) {
        const data = {
            method: 'post',
            url: 'payroll/export/' + id,
            body: json
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payroll successfully exported')

            setTimeout(() => {
                let excelFile = 'byproducts/' + json.type + '-' + id + '.' + json.extension
                const fileLocation = process.env.VUE_APP_API_IMAGE_URL + excelFile
                window.open(fileLocation, '_blank');
            }, 2000);

            router.push('/payroll/' + id)
        }
    },

    async filterPayroll(json, id) {
        const data = {
            method: 'post',
            url: 'payroll/filter/' + id,
            body: json
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },

    async p9Download(year, id, name) {
        const data = {
            method: 'post',
            url: 'payroll/p9',
            body: {
                employee_id: id,
                year: year
            }
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('P9 successfully exported')

            setTimeout(() => {
                let excelFile = 'byproducts/P9-' + name + '-' + year + '.xlsx'
                const fileLocation = process.env.VUE_APP_API_IMAGE_URL + excelFile
                window.open(fileLocation, '_blank');
            }, 2000);

            return true
        }
    },

    async verifyPayslip(id) {
        const data = {
            method: 'get',
            url: 'verifyPayslip/' + id,
        }

        return await api.api(data)
    },
}
