'use strict'
import api from '@/api/api'

export default {
    async getSettings() {
        const data = {
            method: 'get',
            url: 'settings',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async simulatePayslip(json) {
        const data = {
            method: 'post',
            url: 'payslip/simulate',
            body: json
        }

        return await api.api(data)
    },
    async getLogs(json) {
        const data = {
            method: 'post',
            url: 'logs?page=' + json.page,
            body: json
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getModules() {
        const data = {
            method: 'get',
            url: 'modules'
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    }
}
