import CryptoJS from 'crypto-js'

const key = process.env.VUE_APP_CRYPTO_KEY

export function encrypt(data) {
    if (data) {
        return CryptoJS.AES.encrypt(data, key).toString()
    }
}

export function decrypt(data) {
    if (data) {
        let bytes  = CryptoJS.AES.decrypt(data, key);
        return  bytes.toString(CryptoJS.enc.Utf8);
    }
}