<script>
export default {
  name: "Spinner",

  props: {
    size: {
      type: Number,
      default: 50,
    },

    strokeWidth: {
      type: Number,
      default: 2
    },

    animation: {
      type: String,
      default: 'spin'
    },

    stroke: {
      type: String,
      default: '#ad7bed'
    },

    spinnerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <vue-feather
      type="loader"
      :animation="animation"
      :stroke="stroke"
      :size="size"
      :stroke-width="strokeWidth"
      :class="spinnerClass">
  </vue-feather>
</template>

<style scoped>

</style>
