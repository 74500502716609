<template>
  <div class="text-center row">
    <div class="col-md-6 offset-md-3 mt-5">
      <div class="card">
        <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:30px auto;">
          <i class="checkmark">✓</i>
        </div>
        <h1>Payment Successful</h1>
        <p>We have received your payment for the subscription.</p>
        <br>
        <button class="btn btn-lg btn-dark text-uppercase" @click="$router.push('/dashboard')">Dashboard</button>
      </div>
    </div>
  </div>
</template>

<script>
import subscriptionService from "@/api/subscriptionService";

export default {
  name: "PaymentSuccess",
  mounted: async function () {
    await subscriptionService.getSubscription()
    sessionStorage.setItem('paymentSuccess', 'true')
  },
}
</script>

<style scoped>
.checkmark {
  color: #4BB543;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
</style>
