<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5 bg-white">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Edit Casual
              </span>
            </div>
          </template>
          <div class="col-md-12">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="casual_no" class="form-label fw-bold h5 mt-3">Casual No:</label>
                  <input type="text" class="form-control form-control-lg" v-model="casual.casual_no" readonly>
                </div>
                <div class="form-group col-md-3">
                  <label for="first_name" class="form-label fw-bold h5 mt-3">First Name:</label>
                  <input type="text" class="form-control form-control-lg" v-model="casual.first_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="middle_name" class="form-label fw-bold h5 mt-3">Middle Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" v-model="casual.middle_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="last_name" class="form-label fw-bold h5 mt-3">Last Name:</label>
                  <input type="text" class="form-control form-control-lg" v-model="casual.last_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="id_number" class="form-label fw-bold h5 mt-3">ID Number:</label>
                  <input type="text" class="form-control form-control-lg" v-model="casual.id_number">
                </div>
                <div class="form-group col-md-3">
                  <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="0712333555" v-model="casual.phone">
                </div>
                <div class="form-group col-md-3">
                  <label for="gender" class="form-label fw-bold h5 mt-3">Gender:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="casual.gender">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="dob" class="form-label fw-bold h5 mt-3">Date of Birth:</label>
                  <input type="date" class="form-control form-control-lg" v-model="casual.dob">
                </div>
                <div class="form-group col-md-6">
                  <label for="address" class="form-label fw-bold h5 mt-3">Address:</label>
                  <input type="text" class="form-control form-control-lg" v-model="casual.address">
                </div>
                <div class="form-group col-md-3">
                  <label for="salary" class="form-label fw-bold h5 mt-3">Daily Pay:</label>
                  <input type="number" class="form-control form-control-lg" v-model="casual.pay">
                </div>
                <div class="form-group col-md-3">
                  <label for="employment_date" class="form-label fw-bold h5 mt-3">Employment Date:</label>
                  <input type="date" class="form-control form-control-lg" v-model="casual.employment_date">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
                <div class="form-group col-md-4">
                  <label for="department" class="form-label fw-bold h5 mt-3">Department:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="casual.department_id">
                    <option v-for="department in departments" :key="department.id" :value="department.id">{{department.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="work_station" class="form-label fw-bold h5 mt-3">Work Station:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="casual.work_station_id">
                    <option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="duty" class="form-label fw-bold h5 mt-3">Duty: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <textarea type="date" class="form-control form-control-lg" v-model="casual.duty"></textarea>
                </div>
              </div>
            </form>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-4" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import casualService from "@/api/casualService";
import departmentService from "@/api/departmentService";
import stationService from "@/api/stationService";
import {decrypt} from "@/utils/crypto";

export default {
  name: "EditCasual",

  data() {
    return {
      profile: {},
      departments: [],
      stations: [],
      casual: {},
    }
  },

  methods: {
    submitForm: async function () {
      await casualService.updateCasual(this.casual, this.$route.params.id)
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.casual = await casualService.getCasual(this.$route.params.id)
    this.departments = await departmentService.getDepartments()
    this.stations = await stationService.getStations()
  },

}
</script>

<style scoped>

</style>
