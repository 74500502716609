<template>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 d-none d-md-block">
      <div style="background-image:url('../../../sign-up.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 100vh;"
      ></div>
    </div>
    <div class="col-xl-8 col-lg-8 col-md-8">
      <div class="row d-flex align-items-md-center justify-content-center vh-100">
        <div class="col-12 col-sm-8 col-md-12 col-lg-10 col-xl-10 col-xxl-8">
          <Card class="bg-white">
            <div class="modal-body p-1">
              <h2 class="text-center text-uppercase sidebar-brand align-items-center fw-bold logo-text">
                <img src="../../../public/logo.png" width="80px" alt="logo">
              </h2>
              <div class="modal-body p-1">
                <div class="font-size-150 fw-lighter text-center text-uppercase mt-3 mb-4">
                  Sign Up
                </div>
              </div>
              <hr class="my-0" />
              <div class="mt-4">
                <form @submit.prevent="">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="first_name" class="form-label fw-bold h5 mt-3">First Name:</label>
                      <input type="text" class="form-control form-control-lg" placeholder="First Name" v-model="user.first_name">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="last_name" class="form-label fw-bold h5 mt-3">Last Name:</label>
                      <input type="text" class="form-control form-control-lg" placeholder="Last Name" v-model="user.last_name">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="email" class="form-label fw-bold h5 mt-3">Email:</label>
                      <input type="email" class="form-control form-control-lg" placeholder="Email" v-model="user.email">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
                      <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="user.phone">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="password" class="form-label fw-bold h5 mt-3">Password:</label>
                      <input type="password" class="form-control form-control-lg" placeholder="Password" v-model="user.password">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="password_confirmation" class="form-label fw-bold h5 mt-3">Confirm Password:</label>
                      <input type="password" class="form-control form-control-lg" placeholder="Confirm Password" v-model="user.password_confirmation">
                    </div>
                    <div class="col-md-12 d-flex justify-content-between mt-3">
                      <router-link class="btn btn-dark text-uppercase" to="/login">Login</router-link>
                      <button class="btn btn-info text-uppercase" type="submit" @click="signUp()">Sign Up</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import authService from "@/api/authService";

export default {
  name: "SignUp",

  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
      },
    }
  },

  methods: {
    async signUp() {
      await authService.userSignUp(this.user)
    }
  }
}
</script>

<style scoped>

</style>
