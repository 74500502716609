<template>
  <div>
    <div class="row">
      <div class="col-12" @addDepartment="departmentModal = true; departmentMode = 'add'">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Departments ({{departments.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-success btn-sm" @click="departmentModal = true; departmentMode = 'add'">+ Add Department</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="department in departments">
                <td>{{ department.code }}</td>
                <td>{{ department.name }}</td>
                <td>{{ department.description }}</td>
                <td>{{ formatDate(department.created_at) }}</td>
                <td>
                  <button
                      v-if="profile.role !== 'user' && profile.role !== 'admin'"
                      class="btn btn-info btn-sm"
                      @click="departmentModal = true; departmentMode = 'edit'; populateDepartmentEdit(department)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Department Modal -->
    <Modal :open="departmentModal" @closed="departmentModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="departmentMode === 'add'">Add Department</h5>
        <h5 class="modal-title m-0" v-else>Edit Department</h5>
        <button @click="departmentModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Department code" v-model="department.code" readonly>
            </div>
            <div class="form-group col-md-12">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Department name" v-model="department.name">
            </div>
            <div class="form-group col-md-12">
              <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" placeholder="Department description" v-model="department.description">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="departmentModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="departmentMode === 'add'" @click="addDepartment">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateDepartment(department.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Department Modal End -->

  </div>
</template>

<script>
import departmentService from "@/api/departmentService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Departments",

  data(){
    return {
      profile: {},
      loading: true,
      departments: [],
      departmentMode: 'add',
      departmentModal: false,
      department: {
        code: '',
        name: '',
        description: '',
      },
      nextNo: {},
    }
  },

  methods: {
    formatDate,
    async getDepartments() {
      this.departments = await departmentService.getDepartments()
      this.nextNo = await departmentService.getDepartmentNextNo()
      this.department.code = this.nextNo.next
      this.loading = false
    },
    populateDepartmentEdit(department) {
      this.department = department
    },
    async addDepartment() {
      let response = await departmentService.createDepartment(this.department)
      if (response)
        this.departmentModal = false
        await this.getDepartments()
    },
    async updateDepartment(id) {
      let response = await departmentService.updateDepartment(this.department, id)
      if (response)
        this.departmentModal = false
        await this.getDepartments()
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getDepartments()
    if(this.$route.query.add)
      this.departmentModal = true
  },

  watch: {
    departmentModal: function () {
      if (!this.departmentModal) {
        this.department = {
          code: this.nextNo.next,
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
