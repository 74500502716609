'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";
import {encrypt} from "@/utils/crypto";

export default {
    async getEmployees() {
        const data = {
            method: 'get',
            url: 'employees',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            sessionStorage.setItem('employeesNo', encrypt(JSON.stringify(response.data.length)))
            return response.data
        }
    },
    async getEmployeeNextNo() {
        const data = {
            method: 'get',
            url: 'employeeNextNo',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getEmployee(id) {
        const data = {
            method: 'get',
            url: 'employee/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createEmployee(json) {
        const data = {
            method: 'post',
            url: 'employee',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Employee added successfully')
            router.push('/employee/' + response.data.id)
        }
    },
    async updateEmployee(json, id) {
        const data = {
            method: 'patch',
            url: 'employee/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employee updated successfully')
            router.push('/employee/' + id)
        }
    },
    async updateEmployeeStatus(json, id) {
        const data = {
            method: 'patch',
            url: 'employee/status/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employee status updated successfully')
            window.location.reload()
        }
    },
    async uploadEmployeeFile(json) {
        const data = {
            method: 'post',
            url: 'employee/upload',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employees created successfully')
            return true;
        }
    },
    async allowanceNames() {
        const data = {
            method: 'get',
            url: 'allowanceNames',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getEmployeeFiles(id) {
        const data = {
            method: 'get',
            url: 'employee/files/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async updateAvatar(json) {
        const data = {
            method: 'post',
            url: 'employee/avatar',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employee picture updated successfully')
            window.location.reload()
        }
    },
    async addEmployeeFile(json) {
        const data = {
            method: 'post',
            url: 'employee/file',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employee file added successfully')
            return true;
        }
    },
}
