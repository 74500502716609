<script>
export default {
  name: "BlankLayout"
}
</script>

<template>
  <!-- Content with router view -->
  <div class="content-container container-fluid mt-4 mb-5">
    <div class="row">
      <div class="col">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>