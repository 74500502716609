<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-6 col-xl-6 mt-5">
      <Card class="bg-white">
        <div class="text-center">
          <h3 class="color-gray-600">Authentication Redirect</h3>
          <p class="color-gray-500">Please wait while we redirect you to the dashboard...</p>
          <Spinner spinner-class="mb-1" />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import authService from "@/api/authService";
import router from "@/router";

export default {
  name: "SSOAuth",

  data() {
    return {
      loading: true,
    }
  },

  async created() {
    let token = this.$route.query.token
    if (token !== undefined && token !== '') {
      await authService.SSOAuth(token)
    } else {
      await router.push('/login')
    }
  }
}
</script>

<style scoped>

</style>
