<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5 bg-white">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Add Employee
              </span>
            </div>
          </template>
          <div class="col-md-12">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="employee_no" class="form-label fw-bold h5 mt-3">Employee No:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Employee No" v-model="employee.employee_no">
                </div>
                <div class="form-group col-md-3">
                  <label for="first_name" class="form-label fw-bold h5 mt-3">First Name:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="First Name" v-model="employee.first_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="middle_name" class="form-label fw-bold h5 mt-3">Middle Name:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Middle Name" v-model="employee.middle_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="last_name" class="form-label fw-bold h5 mt-3">Last Name:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Last Name" v-model="employee.last_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="id_number" class="form-label fw-bold h5 mt-3">ID Number:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="ID Number" v-model="employee.id_number">
                </div>
                <div class="form-group col-md-3">
                  <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="0712333555" v-model="employee.phone">
                </div>
                <div class="form-group col-md-3">
                  <label for="email" class="form-label fw-bold h5 mt-3">Email:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Email" v-model="employee.email">
                </div>
                <div class="form-group col-md-3">
                  <label for="gender" class="form-label fw-bold h5 mt-3">Gender:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.gender">
                    <option value="" disabled selected>Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="dob" class="form-label fw-bold h5 mt-3">Date of Birth:</label>
                  <input type="date" class="form-control form-control-lg" v-model="employee.dob">
                </div>
                <div class="form-group col-md-3">
                  <label for="disability" class="form-label fw-bold h5 mt-3">Disability:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.disability">
                    <option value="" disabled selected>Select disability</option>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="address" class="form-label fw-bold h5 mt-3">Address:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Address" v-model="employee.address">
                </div>
                <div class="form-group col-md-3">
                  <label for="kin_name" class="form-label fw-bold h5 mt-3">Kin Name:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Kin Name" v-model="employee.kin.name">
                </div>
                <div class="form-group col-md-3">
                  <label for="kin_relationship" class="form-label fw-bold h5 mt-3">Relationship:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Relationship" v-model="employee.kin.relationship">
                </div>
                <div class="form-group col-md-3">
                  <label for="kin_phone" class="form-label fw-bold h5 mt-3">Phone:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="0712333555" v-model="employee.kin.phone">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-5" />
                <div class="form-group col-md-3">
                  <label for="employment_status" class="form-label fw-bold h5 mt-3">Employment Terms:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.tos">
                    <option value="" disabled >Select employment terms</option>
                    <option value="Permanent">Permanent</option>
                    <option value="Contract">Contract</option>
                    <option value="Consultant">Consultant</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="salary" class="form-label fw-bold h5 mt-3">{{ salaryText }}:</label>
                  <input type="number" class="form-control form-control-lg" :placeholder="salaryText" v-model="employee.salary">
                </div>
                <div class="form-group col-md-3">
                  <label for="employment_date" class="form-label fw-bold h5 mt-3">Employment Date:</label>
                  <input type="date" class="form-control form-control-lg" v-model="employee.employment_date">
                </div>
                <div v-if="employee.tos === 'Contract'" class="form-group col-md-3">
                  <label for="termination_date" class="form-label fw-bold h5 mt-3">Termination Date:</label>
                  <input type="date" class="form-control form-control-lg" v-model="employee.termination_date">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-2" />
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct PAYE:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.paye">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NHIF:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.nhif">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NSSF:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.nssf">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct Housing Levy:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.housing">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NITA:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.nita">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Email Payslip:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.email_payslip">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-5" />
                <div class="form-group col-md-4">
                  <label for="department" class="form-label fw-bold h5 mt-3">Department:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.department_id">
                    <option value="" disabled selected>Select department</option>
                    <option v-for="department in departments" :key="department.id" :value="department.id">{{department.name}}</option>
                  </select>
                  <span class="float-end mt-1">
                    <button v-if="profile.role !== 'user'" type="button" class="btn btn-sm btn-outline-success" @click="addDepartment">+ Add Department</button>
                  </span>
                </div>
                <div class="form-group col-md-4">
                  <label for="designation" class="form-label fw-bold h5 mt-3">Designation:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.designation_id">
                    <option value="" disabled selected>Select designation</option>
                    <option v-for="designation in designations" :key="designation.id" :value="designation.id">{{designation.name}}</option>
                  </select>
                  <span class="float-end mt-1">
                    <button v-if="profile.role !== 'user'" type="button" class="btn btn-sm btn-outline-success" @click="addDesignation">+ Add Designation</button>
                  </span>
                </div>
                <div class="form-group col-md-4">
                  <label for="work_station" class="form-label fw-bold h5 mt-3">Work Station:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.work_station_id">
                    <option value="" disabled selected>Select work station</option>
                    <option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}}</option>
                  </select>
                  <span class="float-end mt-1">
                    <button v-if="profile.role !== 'user'" type="button" class="btn btn-sm btn-outline-success" @click="addStation">+ Add Work Station</button>
                  </span>
                </div>
                <div class="form-group col-md-4">
                  <label for="pin_no" class="form-label fw-bold h5 mt-3">PIN No:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="PIN No" v-model="employee.pin_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="nssf_no" class="form-label fw-bold h5 mt-3">NSSF No: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="NSSF No" v-model="employee.nssf_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="nhif_no" class="form-label fw-bold h5 mt-3">SHIF No: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="NHIF No" v-model="employee.nhif_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Bank Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="bankOptions" id="bankName" v-model="employee.bank_name" placeholder="Type to search...">
                  <datalist id="bankOptions">
                    <option v-for="bank in bankNames.banks" :key="bank" :value="bank"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Branch Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="branchOptions" id="branchName" v-model="employee.branch_name" placeholder="Type to search...">
                  <datalist id="branchOptions">
                    <option v-for="branch in bankBranches" :key="branch" :value="branch"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Account Number: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="Account number" v-model="employee.account_no">
                </div>
              </div>
            </form>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-4" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button v-if="profile.role !== 'user'" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import employeeService from "@/api/employeeService";
import departmentService from "@/api/departmentService";
import stationService from "@/api/stationService";
import designationService from "@/api/designationService";
import router from "@/router";
import {decrypt} from "@/utils/crypto";
import bankService from "@/api/bankService";

export default {
  name: "AddEmployee",
  data() {
    return {
      departments: [],
      stations: [],
      designations: [],
      employee: {
        employee_no: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        id_number: '',
        phone: '',
        email: '',
        gender: '',
        dob: '',
        disability: 0,
        address: '',
        salary: '',
        tos: 'Permanent',
        status: '',
        employment_date: '',
        termination_date: '',
        kin: {
          name: '',
          relationship: '',
          phone: '',
        },
        department_id: '',
        designation_id: '',
        work_station_id: '',
        pin_no: '',
        nssf_no: '',
        nhif_no: '',
        bank_name: '',
        account_no: '',
        branch_name: '',
        paye: true,
        nhif: true,
        nssf: true,
        housing: true,
        nita: true,
        email_payslip: true
      },
      profile: {},
      nextNo: {},
      bankNames: {
        banks: [],
        branches: {}
      },
      bankBranches: []
    }
  },
  methods: {
    submitForm: async function () {
      await employeeService.createEmployee(this.employee)
    },
    addDepartment: function () {
      router.push({
        path: 'departments',
        query: { add: 'true' }
      })
    },
    addDesignation: function () {
      router.push({
        path: 'designations',
        query: { add: 'true' }
      })
    },
    addStation: function () {
      router.push({
        path: 'work-stations',
        query: { add: 'true' }
      })
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.nextNo = await employeeService.getEmployeeNextNo()
    this.employee.employee_no = this.nextNo.next
    this.departments = await departmentService.getDepartments()
    this.designations = await designationService.getDesignations()
    this.stations = await stationService.getStations()
    this.bankNames = await bankService.getBankNames()
  },

  computed: {
    salaryText: function () {
      if (this.employee.tos === 'Consultant') {
        return 'Salary incl. VAT'
      } else {
        return 'Salary'
      }
    }
  },

  watch: {
    'employee.bank_name': function () {
      this.bankBranches = this.bankNames.branches[this.employee.bank_name]
    },
    'employee.tos': function () {
      let checked = this.employee.tos !== 'Consultant' && this.employee.tos !== 'Casual';
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = checked);
    }
  },
}
</script>

<style scoped>

</style>
