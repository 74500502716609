'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getDeductions(id) {
        const data = {
            method: 'get',
            url: 'recur-deductions/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createDeduction(json) {
        const data = {
            method: 'post',
            url: 'recur-deduction',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Deduction created successfully')
            return true;
        }
    },
    async updateDeduction(json, id) {
        const data = {
            method: 'patch',
            url: 'recur-deduction/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Deduction updated successfully')
            return true;
        }
    },
    async deleteDeduction(id) {
        const data = {
            method: 'delete',
            url: 'recur-deduction/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Deduction deleted successfully')
            return true;
        }
    },
}
