'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getPayrollPayouts(id) {
        const data = {
            method: 'get',
            url: 'payrollPayouts/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getCasualPayrollPayouts(id) {
        const data = {
            method: 'get',
            url: 'casual-payouts/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createPayout(id) {
        const data = {
            method: 'post',
            url: 'payout/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payout successfully initiated')
            window.location.reload()
        }
    },
    async createCasualPayout(id) {
        const data = {
            method: 'post',
            url: 'casual-payout/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Casuals payout successfully initiated')
            window.location.reload()
        }
    },
    async retryPayout(id) {
        const data = {
            method: 'post',
            url: 'payout-retry/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payout successfully resubmitted')
            window.location.reload()
        }
    },
}
