<template>
  <div>
    <Card>
      <template slot="header">Employee Details</template>
      <div class="row">
        <div v-if="!loading" class="col-md-12">
          <h2 class="text-center">{{ name }}</h2>
        </div>
        <hr class="border-1 border-info border-opacity-75 mt-3" />
        <Nav nav-fill :init-active-tab="1">
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <NavItem title="Details">
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
            <div v-if="!loading" class="row">
              <div class="col-md-2">
                <img class="img-thumbnail bg-dark-subtle" width="100%" :src="avatar" alt="">
                <br>
                <button class="btn btn-primary mt-3 text-uppercase w-100" @click="$refs.refInputEl.click()">Upload Photo</button>
                <input
                    ref="refInputEl"
                    type="file"
                    name="logo"
                    accept=".jpeg,.png,.jpg"
                    hidden
                    @input="changeAvatar"
                >
              </div>
              <div class="col-md-10">
                <div class="col-md-12 row">
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Employee No</h6>
                    <p class="fst-italic">{{ employee.employee_no }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Address</h6>
                    <p class="fst-italic">{{ employee.address }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Phone</h6>
                    <p class="fst-italic">{{ employee.phone }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Email address</h6>
                    <p class="fst-italic">{{ employee.email }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">ID Number</h6>
                    <p class="fst-italic">{{ employee.id_number }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Gender</h6>
                    <p class="fst-italic">{{ employee.gender }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Date of Birth</h6>
                    <p class="fst-italic">{{ formatDate(employee.dob) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Employment Date</h6>
                    <p class="fst-italic">{{ formatDate(employee.employment_date) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">TOS</h6>
                    <p class="fst-italic">{{ employee.tos }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Salary</h6>
                    <p class="fst-italic">{{ Ksh(employee.salary) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Termination Date</h6>
                    <p class="fst-italic">{{ !employee.termination_date ? 'N/A' : formatDate(employee.termination_date) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Status</h6>
                    <p class="fst-italic">
                      <badge :type="getPillByStatus(employee.status)">{{ employee.status }}</badge>
                    </p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Department</h6>
                    <p class="fst-italic">{{ employee.department.name }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Designation</h6>
                    <p class="fst-italic">{{ employee.designation.name }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Work Station</h6>
                    <p class="fst-italic">{{ employee.station.name }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Kin Name</h6>
                    <p class="fst-italic">{{ employee.kin.name }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Kin Relationship</h6>
                    <p class="fst-italic">{{ employee.kin.relationship }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Kin Phone</h6>
                    <p class="fst-italic">{{ employee.kin.phone }}</p>
                  </div>
                </div>
              </div>
              <hr class="border-1 border-info border-opacity-75 mt-3" />
              <div class="col-md-12 row">
                <div class="col-md-3 col-lg-2">
                  <h6 class="mt-3 fw-bold">PIN No</h6>
                  <p class="fst-italic">{{ employee.details.pin_no }}</p>
                </div>
                <div class="col-md-3 col-lg-1">
                  <h6 class="mt-3 fw-bold">SHIF No</h6>
                  <p class="fst-italic">{{ employee.details.nhif_no }}</p>
                </div>
                <div class="col-md-3 col-lg-1">
                  <h6 class="mt-3 fw-bold">NSSF No</h6>
                  <p class="fst-italic">{{ employee.details.nssf_no }}</p>
                </div>
                <div class="col-md-3 col-lg-3">
                  <h6 class="mt-3 fw-bold">Bank name</h6>
                  <p class="fst-italic">{{ employee.details.bank_name }} - {{ employee.details.bank_code }}</p>
                </div>
                <div class="col-md-3 col-lg-2">
                  <h6 class="mt-3 fw-bold">Account No</h6>
                  <p class="fst-italic">{{ employee.details.account_no }}</p>
                </div>
                <div class="col-md-3 col-lg-3">
                  <h6 class="mt-3 fw-bold">Branch name</h6>
                  <p class="fst-italic">{{ employee.details.branch_name }} - {{ employee.details.branch_code }}</p>
                </div>
              </div>
            </div>
          </NavItem>
          <NavItem title="Allowances">
            <div class="col-md-12">
              <div>
                <button v-if="profile.role !== 'user'" class="btn btn-success" @click="allowanceModal = true; allowanceMode = 'add'">+ Add Allowance</button>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Taxable</th>
                    <th scope="col">Created</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="allowance in allowances">
                    <td>{{ allowance.name }}</td>
                    <td>{{ Ksh(allowance.amount) }}</td>
                    <td>{{ allowance.taxable ? 'Yes' : 'No' }}</td>
                    <td>{{ formatDate(allowance.created_at) }}</td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-primary btn-sm"
                          @click="allowanceModal = true; allowanceMode = 'edit'; populateAllowanceEdit(allowance)"
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-danger btn-sm"
                          @click="confirmDialog('Allowance', () => deleteAllowance(allowance.id))"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </NavItem>
          <NavItem title="Recurrent Incomes">
            <div class="col-md-12">
              <div>
                <button v-if="profile.role !== 'user'" class="btn btn-success" @click="incomeModal = true; incomeMode = 'add'">+ Add Recurrent Income</button>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Taxable</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Description</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="income in incomes">
                    <td>{{ income.name }}</td>
                    <td>{{ Ksh(income.amount) }}</td>
                    <td>{{ income.taxable ? 'Yes' : 'No' }}</td>
                    <td>{{ formatDate(income.from) }}</td>
                    <td>{{ formatDate(income.to) }}</td>
                    <td>{{ income.description }}</td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-primary btn-sm"
                          @click="incomeModal = true; incomeMode = 'edit'; populateIncomeEdit(income)"
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-danger btn-sm"
                          @click="confirmDialog('Recurrent Income', () => deleteIncome(income.id))"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </NavItem>
          <NavItem title="Recurrent Deductions">
            <div class="col-md-12">
              <div>
                <button v-if="profile.role !== 'user'" class="btn btn-success" @click="deductionModal = true; deductionMode = 'add'">+ Add Recurrent Deduction</button>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Taxable</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Description</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="deduction in deductions">
                    <td>{{ deduction.name }}</td>
                    <td>{{ Ksh(deduction.amount) }}</td>
                    <td>{{ deduction.taxable ? 'Yes' : 'No' }}</td>
                    <td>{{ formatDate(deduction.from) }}</td>
                    <td>{{ formatDate(deduction.to) }}</td>
                    <td>{{ deduction.description }}</td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-primary btn-sm"
                          @click="deductionModal = true; deductionMode = 'edit'; populateDeductionEdit(deduction)"
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-danger btn-sm"
                          @click="confirmDialog('Recurrent Deduction', () => deleteDeduction(deduction.id))"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </NavItem>
          <NavItem title="Commission / Revenues">
            <div class="row col-md-12">
              <div class="col-md-6">
                <div>
                  <h4 class="float-start me-3">Commission Category</h4>
                  <button v-if="profile.role !== 'user'" class="btn btn-success" @click="employeeCommissionModal = true; employeeCommissionMode = 'add'">+ Add Category</button>
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">From amount</th>
                      <th scope="col">Rate</th>
                      <th></th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="commissionRate !== null">
                      <td>{{ commissionRate.name }}</td>
                      <td>{{ Ksh(commissionRate.amount) }}</td>
                      <td>{{ commissionRate.rate }}%</td>
                      <td>
                        <button
                            v-if="profile.role !== 'user'"
                            class="btn btn-primary btn-sm"
                            @click="employeeCommissionModal = true; employeeCommissionMode = 'edit'; populateEmployeeCommissionEdit(commissionRate.id)"
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                            v-if="profile.role !== 'user'"
                            class="btn btn-danger btn-sm"
                            @click="confirmDialog('Employee Commission Category', () => deleteEmployeeCommission())"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <h4 class="float-start me-3">Revenues</h4>
                  <button v-if="profile.role !== 'user'" class="btn btn-success" @click="employeeRevenueModal = true; employeeRevenueMode = 'add'">+ Add Employee Revenue</button>
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th scope="col">Month</th>
                      <th scope="col">Year</th>
                      <th scope="col">Amount</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="employeeRevenue in employeeRevenues">
                      <td>{{ employeeRevenue.month }}</td>
                      <td>{{ employeeRevenue.year }}</td>
                      <td>{{ Ksh(employeeRevenue.amount) }}</td>
                      <td>
                        <button
                            v-if="profile.role !== 'user'"
                            class="btn btn-primary btn-sm"
                            @click="employeeRevenueModal = true; employeeRevenueMode = 'edit'; populateEmployeeRevenueEdit(employeeRevenue)"
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </NavItem>
          <NavItem title="Files">
            <div class="col-md-12">
              <div>
                <button v-if="profile.role !== 'user'" class="btn btn-success" @click="fileModal = true">+ Add File</button>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Created</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="file in employeeFiles">
                    <td>{{ file.name }}</td>
                    <td>{{ file.description }}</td>
                    <td>{{ formatDate(file.created_at) }}</td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-primary btn-sm"
                          @click="viewFile(file.file)"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </NavItem>
        </Nav>
        <hr class="border-1 border-info border-opacity-75 mt-3" />
        <div class="col-md-12 d-flex justify-content-center">
          <button v-if="profile.role !== 'user'" class="btn btn-success text-uppercase me-4" @click="p9Modal = true">Download P9</button>
          <button v-if="profile.role !== 'user'" class="btn btn-info text-uppercase me-4" @click="changeStatusModal = true">Change Status</button>
          <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-primary text-uppercase" @click="editEmployee">Edit Employee</button>
        </div>
      </div>

      <!-- Add File Modal -->
      <Modal :open="fileModal" @closed="fileModal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0">Add File</h5>
          <button @click="fileModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                <input type="text" class="form-control form-control-lg" list="uploadOptions" id="uploadName" v-model="file.name" placeholder="Type to search...">
                <datalist id="uploadOptions">
                  <option v-for="upload in suggestions.files" :key="upload" :value="upload"></option>
                </datalist>
              </div>
              <div class="form-group col-md-12">
                <label for="amount" class="form-label fw-bold h5 mt-3">File:</label>
                <input class="form-control" type="file" @input="handleFileUpload" name="docs" accept=".jpeg,.png,.jpg,.pdf">
              </div>
              <div class="form-group col-md-12">
                <label for="amount" class="form-label fw-bold h5 mt-3">Description:</label>
                <textarea type="text" class="form-control form-control-lg" v-model="file.description"></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="fileModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" @click="addFile">Submit</button>
        </div>
      </Modal>
      <!-- Add File Modal End -->

      <!-- Download P9 Modal -->
      <Modal :open="p9Modal" @closed="p9Modal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0">Download P9</h5>
          <button @click="p9Modal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Year:</label>
                <select data="" class="form-select form-control input-group" v-model="year">
                  <option value="" disabled selected>Select year</option>
                  <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="p9Modal = false">Cancel</button>
          <button type="button" :class="year !== '' ? '' : 'disabled'" class="btn btn-primary text-uppercase" @click="p9Download">Download</button>
        </div>
      </Modal>
      <!-- Download P9 Modal End -->

      <!-- Change Employee Status Modal -->
      <Modal :open="changeStatusModal" @closed="changeStatusModal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0">Change Employee Status</h5>
          <button @click="changeStatusModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Status:</label>
                <select data="" type="text" class="form-select form-select-lg" v-model="employeeStatus">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="suspended">Suspended</option>
                  <option value="terminated">Terminated</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="changeStatusModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" @click="changeStatus">Update Status</button>
        </div>
      </Modal>
      <!-- Change Employee Status Modal End -->

      <!-- Add Allowance Modal -->
      <Modal :open="allowanceModal" @closed="allowanceModal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0" v-if="allowanceMode === 'add'">Add Allowance</h5>
          <h5 class="modal-title m-0" v-else>Edit Allowance</h5>
          <button @click="allowanceModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                <input type="text" class="form-control form-control-lg" list="allowanceOptions" id="allowanceName" v-model="allowance.name" placeholder="Type to search...">
                <datalist id="allowanceOptions">
                  <option v-for="allowance in suggestions.allowances" :key="allowance" :value="allowance"></option>
                </datalist>
              </div>
              <div class="form-group col-md-12">
                <label for="amount" class="form-label fw-bold h5 mt-3">Amount:</label>
                <input type="number" class="form-control form-control-lg" v-model="allowance.amount">
              </div>
              <div class="form-group col-md-12">
                <label for="taxable" class="form-label fw-bold h5 mt-3">Taxable:</label>
                <select data="" type="text" class="form-select form-select-lg" v-model="allowance.taxable">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="allowanceModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" v-if="allowanceMode === 'add'" @click="addAllowance">Submit</button>
          <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateAllowance(allowance.id)">Update</button>
        </div>
      </Modal>
      <!-- Add Allowance Modal End -->

      <!-- Add Recurrent Income Modal -->
      <Modal :open="incomeModal" @closed="incomeModal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0" v-if="incomeMode === 'add'">Add Recurrent Income</h5>
          <h5 class="modal-title m-0" v-else>Edit Recurrent Income</h5>
          <button @click="incomeModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                <input type="text" class="form-control form-control-lg" list="incomeOptions" id="incomeName" v-model="income.name" placeholder="Type to search...">
                <datalist id="incomeOptions">
                  <option v-for="income in suggestions.incomes" :key="income" :value="income"></option>
                </datalist>
              </div>
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Amount:</label>
                <input type="number" class="form-control form-control-lg" v-model="income.amount">
              </div>
              <div class="form-group col-md-12">
                <label for="taxable" class="form-label fw-bold h5 mt-3">Taxable:</label>
                <select data="" type="text" class="form-select form-select-lg" v-model="income.taxable">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label for="from" class="form-label fw-bold h5 mt-3">From:</label>
                <input type="date" class="form-control form-control-lg" v-model="income.from">
              </div>
              <div class="form-group col-md-12">
                <label for="to" class="form-label fw-bold h5 mt-3">To:</label>
                <input type="date" class="form-control form-control-lg" v-model="income.to">
              </div>
              <div class="form-group col-md-12">
                <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                <input type="text" class="form-control form-control-lg" v-model="income.description">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="incomeModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" v-if="incomeMode === 'add'" @click="addIncome">Submit</button>
          <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateIncome(income.id)">Update</button>
        </div>
      </Modal>
      <!-- Add Recurrent Income Modal End -->

      <!-- Add Recurrent Deduction Modal -->
      <Modal :open="deductionModal" @closed="deductionModal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0" v-if="deductionMode === 'add'">Add Recurrent Deduction</h5>
          <h5 class="modal-title m-0" v-else>Edit Recurrent Deduction</h5>
          <button @click="deductionModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                <input type="text" class="form-control form-control-lg" list="deductionOptions" id="deductionName" v-model="deduction.name" placeholder="Type to search...">
                <datalist id="deductionOptions">
                  <option v-for="deduction in suggestions.deductions" :key="deduction" :value="deduction"></option>
                </datalist>
              </div>
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Amount:</label>
                <input type="number" class="form-control form-control-lg" v-model="deduction.amount">
              </div>
              <div class="form-group col-md-12">
                <label for="taxable" class="form-label fw-bold h5 mt-3">Taxable:</label>
                <select data="" type="text" class="form-select form-select-lg" v-model="deduction.taxable">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label for="from" class="form-label fw-bold h5 mt-3">From:</label>
                <input type="date" class="form-control form-control-lg" v-model="deduction.from">
              </div>
              <div class="form-group col-md-12">
                <label for="to" class="form-label fw-bold h5 mt-3">To:</label>
                <input type="date" class="form-control form-control-lg" v-model="deduction.to">
              </div>
              <div class="form-group col-md-12">
                <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                <input type="text" class="form-control form-control-lg" v-model="deduction.description">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="deductionModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" v-if="deductionMode === 'add'" @click="addDeduction">Submit</button>
          <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateDeduction(deduction.id)">Update</button>
        </div>
      </Modal>
      <!-- Add Recurrent Deduction Modal End -->

      <!-- Add Commission Category Modal -->
      <Modal :open="employeeCommissionModal" @closed="employeeCommissionModal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0" v-if="employeeCommissionMode === 'add'">Add Commission Category</h5>
          <h5 class="modal-title m-0" v-else>Edit Commission Category</h5>
          <button @click="employeeCommissionModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Commission Category:</label>
                <select data="" type="text" class="form-select form-select-lg" v-model="employeeCommission.commission_rate_id">
                  <option v-if="employeeCommissionMode === 'add'" value="" disabled selected>Select commission category</option>
                  <option v-else-if="employeeCommissionMode === 'edit'" value="{{commissionRate.id}}" disabled selected>{{commissionRate.name}} - {{Ksh(commissionRate.amount)}} - {{commissionRate.rate}}%</option>
                  <option v-for="rate in allCommissionRates" :key="rate.id" :value="rate.id">{{rate.name}} - {{Ksh(rate.amount)}} - {{rate.rate}}%</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="employeeCommissionModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" v-if="employeeCommissionMode === 'add'" @click="addEmployeeCommission">Submit</button>
          <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateEmployeeCommission(employeeCommission.id)">Update</button>
        </div>
      </Modal>
      <!-- Add Commission Category Modal End -->

      <!-- Add Employee Revenue Modal -->
      <Modal :open="employeeRevenueModal" @closed="employeeRevenueModal = false">
        <div class="modal-header">
          <h5 class="modal-title m-0" v-if="employeeRevenueMode === 'add'">Add Employee Revenue</h5>
          <h5 class="modal-title m-0" v-else>Edit Employee Revenue</h5>
          <button @click="employeeRevenueModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="month" class="form-label fw-bold h5 mt-3">Month:</label>
                <select data="" type="text" class="form-select form-select-lg" v-model="employeeRevenue.month">
                  <option value="" disabled selected>Select month</option>
                  <option v-for="month in months" :key="month" :value="month">{{month}}</option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label for="year" class="form-label fw-bold h5 mt-3">Year:</label>
                <input v-model="employeeRevenue.year" type="number" class="form-control form-control-lg" />
              </div>
              <div class="form-group col-md-12">
                <label for="name" class="form-label fw-bold h5 mt-3">Amount:</label>
                <input type="number" class="form-control form-control-lg" v-model="employeeRevenue.amount">
              </div>
              <div class="form-group col-md-12">
                <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                <input type="text" class="form-control form-control-lg" v-model="employeeRevenue.description">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
          <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="employeeRevenueModal = false">Cancel</button>
          <button type="button" class="btn btn-primary text-uppercase" v-if="employeeRevenueMode === 'add'" @click="addEmployeeRevenue">Submit</button>
          <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateEmployeeRevenue(employeeRevenue.id)">Update</button>
        </div>
      </Modal>
      <!-- Add Employee Revenue Modal End -->

    </Card>

    <div class="row mt-3">
      <div class="row">
        <h4 class="col-md-8 col-lg-9 mb-4 float-start">{{ name }} <strong>{{ year }}</strong> Stats</h4>
        <div class="col-md-4 col-lg-3 float-end row">
          <label for="month" class="form-label fw-bold h5 col-md-4">Filter:</label>
          <span class="col-md-8">
              <select data="" class="form-select form-control input-group" v-model="year" @change="getEmployeeAnalytics">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
              </select>
            </span>
        </div>
      </div>
      <div class="col-xl-7">
        <Card>
          <div>
            <div class="font-size-100 color-gray-600">
              Annual Gross Total
            </div>
            <div class="fw-bold font-size-150 ">
              Total: {{ formatNumber(this.analytics.total) }}
            </div>
          </div>
          <div>
            <div v-if="statsLoading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
            <GrossBarChart
                v-else
                :data="this.analytics.payrolls"
                :labels="this.analytics.months"
            >
            </GrossBarChart>
          </div>
        </Card>
      </div>
      <div class="col-xl-5">
        <div class="row">
          <Card>
            <div class="font-size-100 color-gray-600">
              Payroll Categories
            </div>
            <div class="offset-lg-1 col-lg-10">
              <div v-if="statsLoading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
              <DeductionsPieChart
                  v-else
                  :data="this.analytics.deductions"
              ></DeductionsPieChart>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import employeeService from "@/api/employeeService";
import formatDate from "../../utils/formatDate";
import allowanceService from "@/api/allowanceService";
import incomeService from "@/api/incomeService";
import deductionService from "@/api/deductionService";
import confirmDialog from "@/utils/confirmDialog";
import Ksh from "@/utils/formatNumber";
import employeeCommissionService from "@/api/employeeCommissionService";
import commissionRateService from "@/api/commissionRateService";
import employeeRevenueService from "@/api/employeeRevenueService";
import analyticsService from "@/api/analyticsService";
import payrollService from "@/api/payrollService";
import {decrypt} from "@/utils/crypto";
import DeductionsPieChart from "@/components/charts/DeductionsPieChart.vue";
import GrossBarChart from "@/components/charts/GrossBarChart.vue";
import formatNumber from "../../utils/formatNumber";
import notify from "@/utils/notify";

export default {
  name: "EmployeeDetails",
  components: {GrossBarChart, DeductionsPieChart},
  data() {
    return {
      Ksh,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      profile: {},
      changeStatusModal: false,
      employeeStatus: '',
      employee: {
        details: {},
        kin: {},
        department: {},
        designation: {},
        station: {}
      },
      avatarUrl: process.env.VUE_APP_API_IMAGE_URL + 'employee/',
      loading: true,
      statsLoading: true,
      allowances: [],
      incomes: [],
      deductions: [],
      allowanceMode: 'add',
      allowanceModal:false,
      allowance: {
        employee_id: this.$route.params.id,
        name: '',
        amount: '',
        taxable: 1,
      },
      incomeMode: 'add',
      incomeModal:false,
      income: {
        employee_id: this.$route.params.id,
        name: '',
        amount: '',
        taxable: 1,
        from: '',
        to: '',
        description: '',
      },
      deductionMode: 'add',
      deductionModal:false,
      deduction: {
        employee_id: this.$route.params.id,
        name: '',
        amount: '',
        taxable: 1,
        from: '',
        to: '',
        description: '',
      },
      commissionRate: {},
      allCommissionRates: [],
      employeeCommissionMode: 'add',
      employeeCommissionModal:false,
      employeeCommission: {
        employee_id: this.$route.params.id,
        commission_rate_id: '',
      },
      employeeRevenues: [],
      employeeRevenueMode: 'add',
      employeeRevenueModal:false,
      employeeRevenue: {
        employee_id: this.$route.params.id,
        month: '',
        year: '',
        amount: '',
      },
      p9Modal: false,
      year: new Date().getFullYear(),
      years: [],
      analytics: {
        payrolls: [],
        months: [],
        deductions: {
          tax: 0,
          nhif: 0,
          nssf: 0,
          housing: 0,
          nita: 0
        }
      },
      suggestions: {
        allowances: [],
        incomes: [],
        deductions: [],
        files: [],
      },
      file: {
        employee_id: this.$route.params.id,
        name: '',
        file: '',
        description: ''
      },
      selectedFile: null,
      employeeFiles: [],
      fileModal: false,
    }
  },
  methods: {
    confirmDialog,
    formatDate,
    formatNumber,
    getEmployeeAnalytics: async function () {
      this.analytics = await analyticsService.getEmployeeAnalytics(this.$route.params.id, this.year)
      this.statsLoading = false
    },
    async getEmployee() {
      this.employee = await employeeService.getEmployee(this.$route.params.id)
      this.loading = false
    },
    editEmployee() {
      this.$router.push('/edit-employee/' + this.$route.params.id);
    },
    async getAllowances() {
      this.allowances = await allowanceService.getAllowances(this.$route.params.id)
    },
    async getYears () {
      this.years = await analyticsService.getYears()
    },
    async getIncomes() {
      this.incomes = await incomeService.getIncomes(this.$route.params.id)
    },
    async getDeductions() {
      this.deductions = await deductionService.getDeductions(this.$route.params.id)
    },
    async getCommissionRates() {
      this.commissionRate = await employeeCommissionService.getCommissionRate(this.$route.params.id)
    },
    async getAllCommissionRates() {
      this.allCommissionRates = await commissionRateService.getCommissionRates()
    },
    async getEmployeeRevenues() {
      this.employeeRevenues = await employeeRevenueService.getEmployeeRevenues(this.$route.params.id)
    },
    async getEmployeeFiles() {
      this.employeeFiles = await employeeService.getEmployeeFiles(this.$route.params.id)
    },
    viewFile(file) {
      const fileLocation = this.avatarUrl + file
      window.open(fileLocation, '_blank');
    },
    async getAllowanceSuggestionNames() {
      if (sessionStorage.getItem('allowanceNames') === null) {
        let suggestions = await employeeService.allowanceNames()
        this.suggestions = suggestions
        sessionStorage.setItem('allowanceNames', JSON.stringify(suggestions))
      } else {
        this.suggestions = JSON.parse(sessionStorage.getItem('allowanceNames'))
      }
    },
    async changeStatus() {
      await employeeService.updateEmployeeStatus(
          { status: this.employeeStatus }, this.$route.params.id
      )
    },
    async p9Download() {
      let response = await payrollService.p9Download(this.year, this.$route.params.id, this.name)
      if (response)
        this.p9Modal = false
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
        case 'suspended':
          return 'warning';
        case 'terminated':
          return 'dark';
      }
    },
    changeAvatar : async function(file) {
      let fileReader = this.processFile(file)
      fileReader.onload = async () => {
        if (typeof fileReader.result === 'string')
          await employeeService.updateAvatar({
            file: fileReader.result,
            employee_id: this.$route.params.id
          })
      }
    },
    handleFileUpload(file) {
      this.selectedFile = file
    },
    addFile : async function() {
      if (this.selectedFile === null) {
        notify.alert.error('Please select a file')
      } else {
        let fileReader = this.processFile(this.selectedFile)
        fileReader.onload = async () => {
          if (typeof fileReader.result === 'string')
            this.file.file = fileReader.result
            let response = await employeeService.addEmployeeFile(this.file)
            this.updateSuggestions('files', this.file.name)
            if (response)
              this.fileModal = false
              await this.getEmployeeFiles()
        }
      }
    },
    processFile : function(file) {
      const fileReader = new FileReader()
      const { files } = file.target
      if (files && files.length) {
        fileReader.readAsDataURL(files[0])
        return fileReader
      }
    },
    populateAllowanceEdit(allowance) {
      this.allowance = allowance
    },
    async addAllowance() {
      let response = await allowanceService.createAllowance(this.allowance)
      this.updateSuggestions('allowances', this.allowance.name)
      if (response)
        this.allowanceModal = false
        await this.getAllowances()
    },
    async updateAllowance(id) {
      let response = await allowanceService.updateAllowance(this.allowance, id)
      if (response)
        this.allowanceModal = false
        await this.getAllowances()
    },
    async deleteAllowance(id) {
      await allowanceService.deleteAllowance(id)
      await this.getAllowances()
    },
    populateIncomeEdit(income) {
      this.income = income
    },
    async addIncome() {
      let response = await incomeService.createIncome(this.income)
      this.updateSuggestions('incomes', this.income.name)
      if (response)
        this.incomeModal = false
        await this.getIncomes()
    },
    async updateIncome(id) {
      let response = await incomeService.updateIncome(this.income, id)
      if (response)
        this.incomeModal = false
        await this.getIncomes()
    },
    async deleteIncome(id) {
      await incomeService.deleteIncome(id)
      await this.getIncomes()
    },
    populateDeductionEdit(deduction) {
      this.deduction = deduction
    },
    async addDeduction() {
      let response = await deductionService.createDeduction(this.deduction)
      this.updateSuggestions('deductions', this.deduction.name)
      if (response)
        this.deductionModal = false
        await this.getDeductions()
    },
    async updateDeduction(id) {
      let response = await deductionService.updateDeduction(this.deduction, id)
      if (response)
        this.deductionModal = false
        await this.getDeductions()
    },
    async deleteDeduction(id) {
      await deductionService.deleteDeduction(id)
      await this.getDeductions()
    },
    async populateEmployeeCommissionEdit(commissionRateId) {
      let commissions = await employeeCommissionService.getEmployeeCommissions(this.$route.params.id)
      this.employeeCommission.id = commissions.id
      this.employeeCommission.commission_rate_id = commissionRateId
      this.employeeCommission.employee_id = this.$route.params.id
    },
    async addEmployeeCommission() {
      let response = await employeeCommissionService.createEmployeeCommission(this.employeeCommission)
      if (response)
        this.employeeCommissionModal = false
      await this.getCommissionRates()
    },
    async updateEmployeeCommission(id) {
      let response = await employeeCommissionService.updateEmployeeCommission(this.employeeCommission, id)
      if (response)
        this.employeeCommissionModal = false
      await this.getCommissionRates()
    },
    async deleteEmployeeCommission() {
      let commissions = await employeeCommissionService.getEmployeeCommissions(this.$route.params.id)
      await employeeCommissionService.deleteEmployeeCommission(commissions.id)
      await this.getCommissionRates()
    },
    populateEmployeeRevenueEdit(employeeRevenue) {
      this.employeeRevenue = employeeRevenue
    },
    async addEmployeeRevenue() {
      let response = await employeeRevenueService.createEmployeeRevenue(this.employeeRevenue)
      if (response)
        this.employeeRevenueModal = false
      await this.getEmployeeRevenues()
    },
    async updateEmployeeRevenue(id) {
      let response = await employeeRevenueService.updateEmployeeRevenue(this.employeeRevenue, id)
      if (response)
        this.employeeRevenueModal = false
      await this.getEmployeeRevenues()
    },
    updateSuggestions(suggestionList, suggestion) {
      let suggestions = JSON.parse(sessionStorage.getItem('allowanceNames'))
      if (!suggestions[suggestionList].includes(suggestion)) {
        suggestions[suggestionList].push(suggestion)
        sessionStorage.setItem('allowanceNames', JSON.stringify(suggestions))
        this.suggestions = suggestions
      }
    }
  },

  watch: {
    allowanceModal: function () {
      if (!this.allowanceModal) {
        this.allowance = {
          employee_id: this.$route.params.id,
        }
      }
    },
    incomeModal: function () {
      if (!this.incomeModal) {
        this.income = {
          employee_id: this.$route.params.id,
        }
      }
    },
    deductionModal: function () {
      if (!this.deductionModal) {
        this.deduction = {
          employee_id: this.$route.params.id,
        }
      }
    },
    employeeCommissionModal: function () {
      if (!this.employeeCommissionModal) {
        this.employeeCommission = {
          employee_id: this.$route.params.id,
        }
      }
    },
    employeeRevenuesModal: function () {
      if (!this.employeeRevenuesModal) {
        this.employeeRevenue = {
          employee_id: this.$route.params.id,
        }
      }
    },
    fileModal: function () {
      if (!this.fileModal) {
        this.file = {
          employee_id: this.$route.params.id,
        }
      }
    }
  },
  async created() {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getEmployee()
    await this.getEmployeeAnalytics()
    this.employeeStatus = this.employee.status
    this.employeeRevenue.month = new Date().toLocaleString('default', {month: 'long'});
    this.employeeRevenue.year = new Date().getFullYear();
    await this.getYears()
    await this.getAllowances()
    await this.getIncomes()
    await this.getDeductions()
    await this.getAllCommissionRates()
    await this.getCommissionRates()
    await this.getEmployeeRevenues()
    await this.getEmployeeFiles()
    await this.getAllowanceSuggestionNames()
  },

  computed: {
    name() {
      return this.employee.first_name + " " + this.employee.middle_name + " " + this.employee.last_name
    },
    avatar() {
      return this.employee.avatar !== null ? this.avatarUrl + this.employee.avatar : 'https://villagesonmacarthur.com/wp-content/uploads/2020/12/Blank-Avatar.png'
    }
  }
}
</script>
