<template>
  <Card>
    <template #header>
      <div>
        Casuals Payments ({{payroll.payslips.length}})
      </div>
    </template>
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center">{{ payroll.code }} - {{ formatDate(payroll.from) }} to {{ formatDate(payroll.to) }}</h2>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div class="col-md-12 table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th scope="col">Casual Code</th>
            <th scope="col">Casual Name</th>
            <th scope="col">Daily Pay</th>
            <th scope="col">Days</th>
            <th scope="col">Total</th>
            <th scope="col">Advance</th>
            <th scope="col">Gross</th>
            <th scope="col">Deduction</th>
            <th scope="col">NET</th>
            <th></th>
          </tr>
          </thead>
          <tbody v-if="!loading">
          <tr v-for="payslip in payroll.payslips" :key="payslip.id">
            <td>{{ payslip.casual.casual_no }}</td>
            <td><a :href="`/casual/${payslip.casual.id}`">{{ casualName(payslip.casual) }}</a></td>
            <td>{{ Ksh.format(payslip.pay) }}</td>
            <td>{{ payroll.days }}</td>
            <td>{{ Ksh.format(payroll.days * payslip.pay) }}</td>
            <td>{{ Ksh.format(payslip.advance) }}</td>
            <td>{{ Ksh.format(payslip.gross) }}</td>
            <td>{{ Ksh.format(payslip.deduction) }}</td>
            <td>{{ Ksh.format(payslip.net) }}</td>
            <td>
              <button
                  v-if="payroll.status === 'draft' && profile.role !== 'user'"
                  class="btn btn-danger btn-sm"
                  @click="confirmDialog('Payslip', () => deletePayslip(payslip.id))">
                Delete
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-uppercase"><strong>Total:</strong></td>
            <td><strong>{{ totalItems(payroll.payslips, 'pay') }}</strong></td>
            <td></td>
            <td><strong>{{ incomeTotal(payroll.payslips, 'pay', payroll.days) }}</strong></td>
            <td><strong>{{ totalItems(payroll.payslips, 'advance') }}</strong></td>
            <td><strong>{{ totalItems(payroll.payslips, 'gross') }}</strong></td>
            <td><strong>{{ totalItems(payroll.payslips, 'deduction') }}</strong></td>
            <td><strong>{{ totalItems(payroll.payslips, 'net') }}</strong></td>
          </tr>
          </tbody>
        </table>
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div class="col-md-12 d-flex justify-content-center">
        <button
            v-if="payroll.status !== 'draft' && profile.role !== 'user' && profile.role !== 'admin' && payroll.payout === null && integrationTypes.includes('mpesa')"
            class="btn btn-info text-uppercase me-4"
            @click="confirmDialog('Make Payments', () => createPayouts(), 'Do you want to make payments for this payroll? This will initiate bank payouts.')"
        >
          Make Payments
        </button>
        <button
            v-if="payroll.status !== 'draft' && payroll.payout !== null"
            class="btn btn-success text-uppercase me-4"
            @click="$router.push('/casualPayrollPayouts/' + $route.params.id)"
        >
          Payout Details
        </button>
        <button
            v-if="payroll.status === 'draft' && profile.role !== 'user' && profile.role !== 'admin'"
            class="btn btn-primary text-uppercase me-4"
            @click="confirmDialog('Confirm Payroll', () => confirmPayroll(), 'Do you want to confirm this payroll?')"
        >
          Actualize Payments
        </button>
        <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
        <button
            v-if="payroll.status === 'draft' && profile.role !== 'user'"
            class="btn btn-danger text-uppercase"
            @click="confirmDialog('Payroll', () => deletePayroll())"
        >
          Delete Payments
        </button>
      </div>
    </div>

  </Card>
</template>

<script>
import casualPayrollService from "@/api/casualPayrollService";
import formatDate from "../../utils/formatDate";
import confirmDialog from "@/utils/confirmDialog";
import {decrypt} from "@/utils/crypto";
import payoutService from "@/api/payoutService";

let Ksh = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2
});

export default {
  name: "CasualDetails",
  data() {
    return {
      profile: {},
      loading: true,
      payroll: {
        payslips: [],
      },
      Ksh,
      organization: {},
      integrationTypes: []
    }
  },
  methods: {
    confirmDialog,
    formatDate,
    async getPayroll() {
      this.payroll = await casualPayrollService.getPayroll(this.$route.params.id)
      this.loading = false
    },
    casualName(casual) {
      let middleName = casual.middle_name === null ? ' ' : casual.middle_name
      return casual.first_name + " " + middleName + " " + casual.last_name
    },
    totalItems(payslips, item) {
      let total = 0
      for (const payslip of payslips) {
        total += payslip[item]
      }
      return Ksh.format(total)
    },
    incomeTotal(payslips, item, days) {
      let total = 0
      for (const payslip of payslips) {
        total += payslip[item] * days
      }
      return Ksh.format(total)
    },
    async confirmPayroll() {
      await casualPayrollService.confirmPayroll(this.$route.params.id)
    },
    async createPayouts() {
      await payoutService.createCasualPayout(this.$route.params.id)
    },
    async deletePayslip(id) {
      await casualPayrollService.deletePayslip(id)
      await this.getPayroll()
    },
    async deletePayroll() {
      await casualPayrollService.deletePayroll(this.$route.params.id)
    },
  },
  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.organization.integrations.forEach(integration => {
      this.integrationTypes.push(integration.type)
    })
    await this.getPayroll()
    localStorage.setItem('casualPayrollStatus', this.payroll.status)
  },

}
</script>

<style scoped>
table td,
table th {
  white-space: nowrap;
  width: 1%;
}
</style>
