<template>
  <Card>
    <template #header>
      <div>
        Payslips ({{payroll.payslips.length}})
        <span v-if="profile.role !== 'user'">
          <span class="float-end form-group">
            <button class="btn btn-success" @click="exportPayroll">Export</button>
          </span>
          <span class="float-end me-3">
            <select data="" class="form-select form-control input-group" v-model="exportFormat">
              <option value="" disabled selected>Select format</option>
              <option value="xlsx">Excel</option>
              <option value="csv">CSV</option>
            </select>
          </span>
          <span class="float-end me-3">
            <select data="" class="form-select form-control input-group" v-model="exportType">
              <option value="" disabled selected>Select type</option>
              <optgroup label="PAYROLL">
                <option value="all">ALL</option>
              </optgroup>
              <optgroup label="DEDUCTIONS">
                <option value="nhif">NHIF</option>
                <option value="nssf">NSSF</option>
                <option value="SEC_B">PAYE</option>
              </optgroup>
              <optgroup label="BANKS">
                <option value="co-op-bank">CO-OP BANK</option>
                <option value="equity-bank">EQUITY BANK</option>
                <option value="kcb-bank">KCB BANK</option>
                <option value="ncba-bank">NCBA BANK</option>
                <option value="national-bank">NATIONAL BANK</option>
              </optgroup>
            </select>
          </span>
        </span>
      </div>
    </template>
    <div class="row" id="printable">
      <div class="col-md-12">
        <h2 class="text-center">{{ payroll.code }} - {{ payroll.month }}, {{ payroll.year }}</h2>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3 no-print" />
      <div class="col-md-12 no-print">
        <div class="row bg-info rounded shadow-lg p-2">
          <div class="form-group col-md-3">
            <label class="form-label fw-bold text-white h6">Department:</label>
            <select data="" type="text" class="form-select" v-model="filter.department_id" @change="filterPayroll">
              <option value="" selected>All departments</option>
              <option v-for="department in departments" :key="department.id" :value="department.id">{{department.name}}</option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label class="form-label fw-bold text-white h6">Designation:</label>
            <select data="" type="text" class="form-select" v-model="filter.designation_id" @change="filterPayroll">
              <option value="" selected>All designations</option>
              <option v-for="designation in designations" :key="designation.id" :value="designation.id">{{designation.name}}</option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label class="form-label fw-bold text-white h6">Work station:</label>
            <select data="" type="text" class="form-select" v-model="filter.work_station_id" @change="filterPayroll">
              <option value="" selected>All work stations</option>
              <option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}}</option>
            </select>
          </div>
          <div class="form-group col-md-3" style="padding-top: 30px">
            <button class="btn btn-warning" @click="resetFilter">Reset Filter</button>
            <button class="btn btn-primary ms-3" @click="printDiv('printable')">Print</button>
          </div>
        </div>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div class="col-md-12 table-responsive">
        <section slot="pdf-content">
          <table class="table table-hover">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">Employee Code</th>
              <th scope="col">Employee Name</th>
              <th scope="col">Salary</th>
              <th v-for="name in payroll.incomeNames" :key="name" scope="col">
                {{ name }}
              </th>
              <th scope="col">Gross</th>
              <th scope="col">Taxable</th>
              <th scope="col">Income Tax</th>
              <th v-for="name in payroll.deductionNames" :key="name" scope="col">
                {{ name }}
              </th>
              <th scope="col">Tax Relief</th>
              <th scope="col">SHIF Relief</th>
              <th scope="col">AHL Relief</th>
              <th scope="col">Total Deductions</th>
              <th scope="col">Net</th>
              <th></th>
              <th></th>
            </tr>
            </thead>
            <tbody v-if="!loading">
            <tr v-for="payslip in payroll.payslips" :key="payslip.id">
              <td><button class="btn btn-info btn-sm no-print" @click="payslipDetails(payslip.id)">View</button></td>
              <td>
                <button
                    v-if="payroll.status === 'draft' && profile.role !== 'user'"
                    class="btn btn-danger btn-sm no-print"
                    @click="confirmDialog('Payslip', () => deletePayslip(payslip.id))">
                  Delete
                </button>
              </td>
              <td>{{ payslip.employee.employee_no }}</td>
              <td><a :href="`/employee/${payslip.employee.id}`">{{ employeeName(payslip.employee) }}</a></td>
              <td>{{ Ksh.format(payslip.salary) }}</td>
              <td v-for="income in payslip.sortedIncomes" :key="income.id">
                {{ Ksh.format(income.amount) }}
              </td>
              <td>{{ Ksh.format(payslip.gross) }}</td>
              <td>{{ Ksh.format(payslip.taxable) }}</td>
              <td>{{ Ksh.format(payslip.income_tax) }}</td>
              <td v-for="deduction in payslip.sortedDeductions" :key="deduction.id">
                {{ Ksh.format(deduction.amount) }}
              </td>
              <td>{{ Ksh.format(payslip.tax_relief) }}</td>
              <td>{{ Ksh.format(payslip.nhif_relief) }}</td>
              <td>{{ Ksh.format(payslip.housing_relief) }}</td>
              <td>{{ Ksh.format(employeeAmounts(payslip.deductions)) }}</td>
              <td>{{ Ksh.format(payslip.net) }}</td>
              <td><button class="btn btn-info btn-sm no-print" @click="payslipDetails(payslip.id)">View</button></td>
              <td>
                <button
                    v-if="payroll.status === 'draft' && profile.role !== 'user'"
                    class="btn btn-danger btn-sm no-print"
                    @click="confirmDialog('Payslip', () => deletePayslip(payslip.id))">
                  Delete
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="text-uppercase"><strong>Total:</strong></td>
              <td><strong>{{ totalItems(payroll.payslips, 'salary') }}</strong></td>
              <td v-for="(name, index) in payroll.incomeNames">
                <strong>{{ totalEmployeeItems('sortedIncomes', index) }}</strong>
              </td>
              <td><strong>{{ totalItems(payroll.payslips, 'gross') }}</strong></td>
              <td><strong>{{ totalItems(payroll.payslips, 'taxable') }}</strong></td>
              <td><strong>{{ totalItems(payroll.payslips, 'income_tax') }}</strong></td>
              <td v-for="(name, index) in payroll.deductionNames">
                <strong>{{ totalEmployeeItems('sortedDeductions', index) }}</strong>
              </td>
              <td><strong>{{ totalItems(payroll.payslips, 'tax_relief') }}</strong></td>
              <td><strong>{{ totalItems(payroll.payslips, 'nhif_relief') }}</strong></td>
              <td><strong>{{ totalItems(payroll.payslips, 'housing_relief') }}</strong></td>
              <td><strong>{{ calculateTotalDeductions() }}</strong></td>
              <td><strong>{{ totalItems(payroll.payslips, 'net') }}</strong></td>
            </tr>
            </tbody>
          </table>
        </section>
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div class="col-md-12 d-flex justify-content-center no-print">
        <button
            v-if="payroll.status !== 'draft' && profile.role !== 'user' && profile.role !== 'admin' && payroll.payout === null && integrationTypes.includes('bank')"
            class="btn btn-info text-uppercase me-4"
            @click="confirmDialog('Make Payments', () => createPayouts(), 'Do you want to make payments for this payroll? This will initiate bank payouts.')"
        >
          Make Payments
        </button>
        <button
            v-if="payroll.status !== 'draft' && payroll.payout !== null"
            class="btn btn-success text-uppercase me-4"
            @click="$router.push('/payrollPayouts/' + $route.params.id)"
        >
          Payout Details
        </button>
        <button
            v-if="payroll.status === 'draft' && profile.role !== 'user' && profile.role !== 'admin'"
            class="btn btn-primary text-uppercase me-4"
            @click="confirmDialog('Confirm Payroll', () => confirmPayroll(), 'Do you want to confirm this payroll?')"
        >
          Actualize Payroll
        </button>
        <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
        <button
            v-if="payroll.status === 'draft' && profile.role !== 'user'"
            class="btn btn-danger text-uppercase"
            @click="confirmDialog('Payroll', () => deletePayroll())"
        >
          Delete Payroll
        </button>
      </div>
    </div>

  </Card>
</template>

<script>
import payrollService from "@/api/payrollService";
import formatDate from "../../utils/formatDate";
import router from "@/router";
import _ from 'lodash'
import confirmDialog from "@/utils/confirmDialog";
import departmentService from "@/api/departmentService";
import designationService from "@/api/designationService";
import stationService from "@/api/stationService";
import notify from "@/utils/notify";
import {decrypt} from "@/utils/crypto";
import payoutService from "@/api/payoutService";

let Ksh = new Intl.NumberFormat();

export default {
  name: "EmployeeDetails",
  data() {
    return {
      profile: {},
      departments: [],
      stations: [],
      designations: [],
      loading: true,
      payroll: {
        incomeNames: [],
        deductionNames: [],
        payslips: [
          {
            employee: {},
            sortedIncomes: [],
            sortedDeductions: [],
            incomes: [],
            deductions: [],
          }
        ],
      },
      filter: {
        department_id: '',
        work_station_id: '',
        designation_id: '',
      },
      exportType: '',
      exportFormat: '',
      Ksh,
      organization: {},
      integrationTypes: [],
    }
  },
  methods: {
    confirmDialog,
    formatDate,
    async getPayroll() {
      this.payroll = await payrollService.getPayroll(this.$route.params.id)
      this.loading = false
    },
    employeeName(employee) {
      return employee.first_name + " " + employee.middle_name + " " + employee.last_name
    },
    payslipDetails(id) {
      router.push('/payslip/' + id)
    },
    totalItems(payslips, item) {
      let total = 0
      for (const payslip of payslips) {
        total += payslip[item]
      }
      return Ksh.format(total)
    },
    employeeAmounts(items) {
      let total = 0
      for (const item of items) {
        total += item.amount
      }
      return total
    },
    totalEmployeeItems(item, key) {
      let allItemsAmounts = []
      for (const payslip of this.payroll.payslips) {
        let allItems = []
        allItems.push(payslip[item][key])
        for (const items of allItems) {
          allItemsAmounts.push(items.amount)
        }
      }
      return Ksh.format(_.sum(allItemsAmounts))
    },
    calculateTotalDeductions() {
      let allItemsAmounts = []
      for (const payslip of this.payroll.payslips) {
        let allItems = []
        allItems.push(payslip.deductions)
        for (const items of allItems) {
          for (const item of items) {
            allItemsAmounts.push(item.amount)
          }
        }
      }
      return Ksh.format(_.sum(allItemsAmounts))
    },
    async confirmPayroll() {
      await payrollService.confirmPayroll(this.$route.params.id)
    },
    async createPayouts() {
      await payoutService.createPayout(this.$route.params.id)
    },
    async deletePayslip(id) {
      await payrollService.deletePayslip(id)
      await this.getPayroll()
    },
    async deletePayroll() {
      await payrollService.deletePayroll(this.$route.params.id)
    },
    async exportPayroll() {
      if (this.exportType === '' || this.exportFormat === '') {
        notify.alert.info('Please select export type and format')
      } else {
        await payrollService.exportPayroll({
              filter: this.filter,
              type: this.exportType,
              extension: this.exportFormat
            },
            this.$route.params.id
        )
      }
    },
    async filterPayroll() {
      this.loading = true
      this.payroll = await payrollService.filterPayroll(this.filter, this.$route.params.id)
      this.loading = false
    },
    async resetFilter() {
      window.location.reload()
    },
    printDiv(divId) {
      let printContents = document.getElementById(divId).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  },
  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.organization.integrations.forEach(integration => {
      this.integrationTypes.push(integration.type)
    })
    await this.getPayroll()
    this.departments = await departmentService.getDepartments()
    this.designations = await designationService.getDesignations()
    this.stations = await stationService.getStations()
    localStorage.setItem('payrollStatus', this.payroll.status)
    document.title = this.payroll.code + '-' + this.payroll.month + '-' + this.payroll.year
  },

}
</script>

<style scoped>
@media print {
  table th,
  table td {
    white-space: break-spaces !important;
    font-size: 9px !important;
    padding: 1px 1px !important;
  }

  .no-print {
    display: none !important;
  }
}
table td {
  white-space: nowrap;
  width: 1%;
}
</style>
