'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getIncomes(id) {
        const data = {
            method: 'get',
            url: 'recur-incomes/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createIncome(json) {
        const data = {
            method: 'post',
            url: 'recur-income',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Income created successfully')
            return true;
        }
    },
    async updateIncome(json, id) {
        const data = {
            method: 'patch',
            url: 'recur-income/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Income updated successfully')
            return true;
        }
    },
    async deleteIncome(id) {
        const data = {
            method: 'delete',
            url: 'recur-income/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Income deleted successfully')
            return true;
        }
    },
}
