<template>
  <div class="row">
    <div class="col-12">
      <Card body-classes="p-0" class="mb-5">
        <template v-slot:header>
          <div class="d-flex align-items-center justify-content-between">
            <span>
              Payslip Templates ({{templates.length}})
            </span>
          </div>
        </template>
        <div class="row mb-3">
          <h5 class="fw-bold">Template Colors</h5>
          <div class="row">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-5">
                  <label for="myColor" class="form-label">Light color(select a color)</label>
                  <input type="color" class="form-control form-control-color w-100" id="myColor" v-model="organization.light_color">
                </div>
                <div class="form-group col-md-5">
                  <label for="myColor" class="form-label">Dark color(select a color)</label>
                  <input type="color" class="form-control form-control-color w-100" id="myColor" v-model="organization.dark_color">
                </div>
                <div class="form-group col-md-2" style="padding-top: 35px">
                  <button type="button" class="btn btn-outline-primary btn-sm text-uppercase w-100" @click="updateColors">Change</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
        <div v-else class="row">
          <h5 class="fw-bold">Templates</h5>
          <div v-for="template in templates" :key="template.id" class="col-xl-2 col-md-4">
            <div class="text-center">
              <h5 class="text-dark">{{template.description}}</h5>
            </div>
            <Card>
              <div class="text-center">
                <img :src="apiURL + template.image" alt="template" class="img-fluid">
              </div>
              <br>
              <div>
                <button class="btn btn-outline-info btn-sm w-100 text-uppercase mb-3" @click="selectTemplate(template.id); previewModal = true">Preview</button>
                <button
                    class="btn btn-outline-primary btn-sm w-100 text-uppercase"
                    :class="checkTemplate(template.id)"
                    @click="changeTemplate(template.id)">{{ getName(template.id) }}</button>
              </div>
            </Card>
          </div>
        </div>
      </Card>
    </div>

  </div>
</template>

<script>

import accountService from "@/api/accountService";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Templates",

  data() {
    return {
      templates: [],
      templateHtml: '',
      organization: {},
      previewModal: false,
      loading: true,
      apiURL: process.env.VUE_APP_API_PUBLIC_URL,
      baseURL: process.env.VUE_APP_BASE_URL
    }
  },

  methods: {
    async updateColors() {
      await accountService.updateColors({
        light_color: this.organization.light_color,
        dark_color: this.organization.dark_color
      })
      await this.$router.go(0)
    },
    checkTemplate(id) {
      if (this.organization.template_id === id) {
        return 'disabled btn-outline-dark'
      }
    },
    async changeTemplate(id) {
      await accountService.setTemplate(id)
      await this.$router.go(0)
    },
    getName(id) {
      return this.organization.template_id === id ? 'Current' : 'Apply'
    },
    selectTemplate(id) {
      window.open(this.baseURL + 'preview/' + id, '_blank')
    }
  },

  async created() {
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.templates = await accountService.getTemplates()
    this.loading = false
  },
}
</script>

<style scoped>

</style>

