<template>
  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-6 col-lg-4 col-xl-3 mt-5">
      <Card class="bg-white">
        <div class="modal-body p-1">
          <h2 class="text-center text-uppercase sidebar-brand align-items-center fw-bold logo-text">
            <img src="../../../public/logo.png" width="80px" alt="logo">
          </h2>
          <div class="modal-body p-1">
            <div class="font-size-150 fw-lighter text-center text-uppercase mt-3 mb-4">
              Reset Password
            </div>
          </div>
          <hr class="my-0" />
          <div class="mt-4">
            <form @submit.prevent="">
              <UInput v-model="email" placeholder="Email">
                <template #label>Email address</template>
              </UInput>
              <hr class="border-1 border-info border-opacity-75 mt-4" />
              <div class=" mt-4 d-flex flex-row justify-content-between align-items-center">
                <button type="button" class="btn btn-dark text-uppercase" @click="$router.push('/login')">Cancel</button>
                <button type="button" class="btn btn-info text-uppercase" @click="resetSubmit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>
<script>

import authService from "@/api/authService";
import router from "@/router";
export default {
  name: 'Login',
  data() {
    return {
      email: '',
    }
  },
  methods: {
    async resetSubmit() {
      await authService.resetPassword({email: this.email})
    },
  }
}
</script>
