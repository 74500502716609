<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Billing History ({{billings.length}})
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Package</th>
                <th scope="col">Action</th>
                <th scope="col">Duration</th>
                <th scope="col">Method</th>
                <th scope="col">Reference</th>
                <th scope="col">Amount</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="billing in billings" :key="billing.id">
                <td>{{ billing.payment.details.package }}</td>
                <td>{{ billing.payment.details.type }}</td>
                <td>{{ billing.payment.details.duration }} Months</td>
                <td>{{ billing.payment.details.method }}</td>
                <td>{{ billing.payment.details.reference }}</td>
                <td>{{ formatNumber(billing.payment.details.amount) }}</td>
                <td>{{ formatDate(billing.created_at) }}</td>
                <td><button ref="btnPrint" class="btn btn-sm btn-success" @click="printReceipt(billing)">Receipt</button></td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- TODO: Print Receipt -->

    <!-- Print Receipt -->
    <div id="printReceipt">
      <div class="row">
        <div class="col-md-12">
          <img src="https://resley.tech/images/resley-logo.png" height="75" alt="Resley Tech" />
          <div>

                <span style="font-size: 25px">Resley Tech Ltd</span><br>
                <div> Oginga Odinga Street, Kisumu.<br>
                  +254 719 232 782<br>
                  info@resley.tech<br>
                  https://resley.tech
                </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <hr>
          <h4 class="text-center">Payment Receipt</h4>
          <hr>

          <div>
            <div class="col-md-12">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">Package</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Method</th>
                  <th scope="col">Reference</th>
                  <th scope="col">Amount</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ billing.payment.details.package }}</td>
                    <td>{{ billing.payment.details.duration }} Months</td>
                    <td>{{ billing.payment.details.method }}</td>
                    <td>{{ billing.payment.details.reference }}</td>
                    <td>{{ formatNumber(billing.payment.details.amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Print Receipt End -->

  </div>
</template>


<script>
import subscriptionService from "@/api/subscriptionService";
import formatDate from "../../utils/formatDate";
import formatNumber from "../../utils/formatNumber";

export default {
  name: "Billing",

  data(){
    return {
      loading: true,
      billing: JSON.parse(sessionStorage.getItem('billing')) ?? {
        payment: {
          details: {}
        }
      },
      billings: [{
        payment: {
          details: {}
        }
      }],
    }
  },

  methods: {
    formatNumber,
    formatDate,
    printReceipt(billing) {
      sessionStorage.setItem('billing', JSON.stringify(billing))
      let body = document.getElementById('printReceipt').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = body;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
      return false;
    }
  },

  created: async function () {
    let billings = await subscriptionService.getSubscriptions()
    this.billings = billings.filter(function(item){ return item['payment'] !== null })
    this.loading = false
  },
}
</script>

<style scoped>
@media screen{#printReceipt{display:none;}}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}
</style>
