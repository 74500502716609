<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                User ({{users.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user'" class="btn btn-success btn-sm" @click="addUserAccounts">+ Add User</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="user in users">
                <td>{{ name(user) }}</td>
                <td>{{ user.phone }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.role }}</td>
                <td><badge :type="getPillByStatus(user.status)">{{ user.status }}</badge></td>
                <td>{{ formatDate(user.created_at) }}</td>
                <td>
                  <button
                      v-if="profile.role !== 'user'"
                      class="btn btn-primary btn-sm"
                      @click="userModal = true; userMode = 'edit'; populateUserEdit(user)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add User Modal -->
    <Modal :open="userModal" @closed="userModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="userMode === 'add'">Add User</h5>
        <h5 class="modal-title m-0" v-else>Edit User</h5>
        <button @click="userModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="first_name" class="form-label fw-bold h5 mt-3">First Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="First Name" v-model="user.first_name">
            </div>
            <div class="form-group col-md-6">
              <label for="last_name" class="form-label fw-bold h5 mt-3">Last Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Last Name" v-model="user.last_name">
            </div>
            <div class="form-group col-md-6">
              <label for="email" class="form-label fw-bold h5 mt-3">Email:</label>
              <input type="email" class="form-control form-control-lg" placeholder="Email" v-model="user.email">
            </div>
            <div class="form-group col-md-6">
              <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="user.phone">
            </div>
            <div v-if="user.role !== 'primary'" class="form-group col-md-6">
              <label for="role" class="form-label fw-bold h5 mt-3">Role:</label>
              <select data="" class="form-select form-select-lg" v-model="user.role">
                <option value="" disabled selected>Select role</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </div>
            <div class="form-group col-md-6" v-if="userMode === 'edit' && user.role !== 'primary'">
              <label for="status" class="form-label fw-bold h5 mt-3">Status:</label>
              <select data="" class="form-select form-select-lg" v-model="user.status">
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
        </form>
        <div v-if="userMode === 'add' || userMode === 'edit' && user.role !== 'primary'" class="row">
          <h5 class="mt-4 mb-3">User roles explained</h5>
          <div class="accordion">
            <div class="accordion-item shadow-none" id="primary" @click="accordionToggle('primary')">
              <div class="accordion-header collapsed"><b>Primary admin role</b></div>
              <div class="collapse-holder bg-info-subtle d-none">
                <div class="accordion-body">
                  <div>
                    <ul>
                      <li>This user has full access.</li>
                      <li>Can access all modules and pages.</li>
                      <li>Can create, update, and remove data in all modules.</li>
                      <li>Can approve the final payroll.</li>
                      <li>Can create and manage other users.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item shadow-none" id="admin" @click="accordionToggle('admin')">
              <div class="accordion-header collapsed"><b>Admin role</b></div>
              <div class="collapse-holder bg-info-subtle d-none">
                <div class="accordion-body">
                  <div>
                    <ul>
                      <li>This user has limited access.</li>
                      <li>Cannot access Account and Users module.</li>
                      <li>Can create and update data in allowed modules.</li>
                      <li>Can create and manage the payroll.</li>
                      <li>Cannot approve the final payroll.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item shadow-none" id="user" @click="accordionToggle('user')">
              <div class="accordion-header collapsed"><b>User role</b></div>
              <div class="collapse-holder bg-info-subtle d-none">
                <div class="accordion-body">
                  <div>
                    <ul>
                      <li>This user only has read access.</li>
                      <li>Cannot access Account and Users module.</li>
                      <li>Can only view data in allowed modules.</li>
                      <li>Cannot create, update or remove any data.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="userModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="userMode === 'add'" @click="addUser">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateUser(user.id)">Update</button>
      </div>
    </Modal>
    <!-- Add User Modal End -->

  </div>
</template>

<script>
import userService from "@/api/userService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";

export default {
  name: "User",

  data(){
    return {
      profile: {},
      loading: true,
      users: [],
      userMode: 'add',
      userModal: false,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        role: '',
        status: '',
      },
    }
  },

  methods: {
    formatDate,
    getUsers: async function () {
      this.users = await userService.getUsers();
      this.loading = false
    },
    name (user) {
      return `${user.first_name} ${user.last_name}`
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
      }
    },
    populateUserEdit(user) {
      this.user = user
    },
    addUserAccounts() {
      let usersPage = process.env.VUE_APP_ACCOUNTS_URL + 'users'
      window.open(usersPage, '_self')
    },
    async addUser() {
      let response = await userService.createUser(this.user)
      if (response)
        this.userModal = false
        await this.getUsers()
    },
    async updateUser(id) {
      let response = await userService.updateUser(this.user, id)
      if (response)
        this.userModal = false
        await this.getUsers()
    },
    accordionToggle: function (id) {
      let ids = ["primary", "admin", "user"];
      for (let i = 0; i < ids.length; i++) {
        let accordion = document.getElementById(ids[i]);
        let collapseHolder = accordion.getElementsByClassName("collapse-holder");
        if (ids[i] === id) {
          if (collapseHolder[0].classList.contains("d-none")) {
            collapseHolder[0].classList.remove("d-none");
          } else {
            collapseHolder[0].classList.add("d-none");
          }
        } else {
          collapseHolder[0].classList.add("d-none");
        }
      }
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getUsers()
    if(this.$route.query.add)
      this.userModal = true
  },

  watch: {
    userModal: function () {
      if (!this.userModal) {
        this.user = {}
      }
    },
  }

}
</script>

<style scoped>

</style>
