'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";

export default {
    async getAllowances(id) {
        const data = {
            method: 'get',
            url: 'allowances/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createAllowance(json) {
        const data = {
            method: 'post',
            url: 'allowance',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Allowance created successfully')
            return true;
        }
    },
    async updateAllowance(json, id) {
        const data = {
            method: 'patch',
            url: 'allowance/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Allowance updated successfully')
            return true;
        }
    },
    async deleteAllowance(id) {
        const data = {
            method: 'delete',
            url: 'allowance/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Allowance deleted successfully')
            return true;
        }
    },
    async uploadAllowancesFile(json) {
        const data = {
            method: 'post',
            url: 'allowances/upload',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Allowances uploaded successfully')
        } else {
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        }
    }
}
