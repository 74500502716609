'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getDesignations() {
        const data = {
            method: 'get',
            url: 'designations',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getDesignationNextNo() {
        const data = {
            method: 'get',
            url: 'designationNextNo',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createDesignation(json) {
        const data = {
            method: 'post',
            url: 'designation',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Designation created successfully')
            return true;
        }
    },
    async updateDesignation(json, id) {
        const data = {
            method: 'patch',
            url: 'designation/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Designation updated successfully')
            return true;
        }
    },
}
