<template>
  <div class="row justify-content-center">
    <div class="col-md-4 col-sm-8 mt-5">
      <Card class="bg-white">
        <div class="modal-body p-1">
          <div class="font-size-150 fw-bold text-center text-uppercase">
            Change Password
          </div>
          <div class="font-size-100 color-gray-600 text-center">
            First time login. Change of password required.
          </div>
          <div class="mt-4">
            <form @submit.prevent="">
              <UInput v-model="user.password" placeholder="New password" type="password">
                <template #label>New Password</template>
              </UInput>
              <UInput v-model="user.password_confirmation" placeholder="Confirm password" type="password">
                <template #label>Confirm Password</template>
              </UInput>
              <hr class="border-1 border-info border-opacity-75 mt-4" />
              <div class=" mt-4 d-flex flex-row justify-content-between align-items-center">
                <button class="btn btn-dark text-uppercase" @click="$router.push('/login')">Cancel</button>
                <button class="btn btn-info text-uppercase" @click="updateSubmit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>
<script>

import userService from "@/api/userService";
import {decrypt} from "@/utils/crypto";
export default {
  name: 'Login',
  data() {
    return {
      user: {
        password: '',
        password_confirmation: '',
      },
    }
  },
  methods: {
    async updateSubmit() {
      let profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
      await userService.changePassword(this.user, profile.id)
    },
  }
}
</script>
