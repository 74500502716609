<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Commission Rates ({{commissionRates.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-success btn-sm" @click="commissionRateModal = true; commissionRateMode = 'add'">+ Add Commission Rate</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">From amount</th>
                <th scope="col">Rate</th>
                <th scope="col">Description</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="commissionRate in commissionRates">
                <td>{{ commissionRate.name }}</td>
                <td>{{ Ksh(commissionRate.amount) }}</td>
                <td>{{ commissionRate.rate }}%</td>
                <td>{{ commissionRate.description }}</td>
                <td>{{ formatDate(commissionRate.created_at) }}</td>
                <td>
                  <button
                      v-if="profile.role !== 'user' && profile.role !== 'admin'"
                      class="btn btn-info btn-sm"
                      @click="commissionRateModal = true; commissionRateMode = 'edit'; populateCommissionRateEdit(commissionRate)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Commission Rate Modal -->
    <Modal :open="commissionRateModal" @closed="commissionRateModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="commissionRateMode === 'add'">Add Commission Rate</h5>
        <h5 class="modal-title m-0" v-else>Edit Commission Rate</h5>
        <button @click="commissionRateModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Commission name" v-model="commissionRate.name">
            </div>
            <div class="form-group col-md-12">
              <label for="amount" class="form-label fw-bold h5 mt-3">From amount:</label>
              <input type="number" class="form-control form-control-lg" placeholder="From amount" v-model="commissionRate.amount">
            </div>
            <div class="form-group col-md-12">
              <label for="rate" class="form-label fw-bold h5 mt-3">Rate (%):</label>
              <input type="number" class="form-control form-control-lg" placeholder="Rate (0-100)" v-model="commissionRate.rate">
            </div>
            <div class="form-group col-md-12">
              <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" placeholder="Description" v-model="commissionRate.description">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="commissionRateModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="commissionRateMode === 'add'" @click="addCommissionRate">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateCommissionRate(commissionRate.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Commission Rate Modal End -->

  </div>
</template>

<script>
import commissionRateService from "@/api/commissionRateService";
import formatDate from "../../utils/formatDate";
import Ksh from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";

export default {
  name: "CommissionRates",
  data(){
    return {
      profile: {},
      loading: true,
      commissionRates: [],
      commissionRateMode: 'add',
      commissionRateModal: false,
      commissionRate: {
        name: '',
        amount: '',
        rate: '',
        description: '',
      },
    }
  },

  methods: {
    Ksh,
    formatDate,
    async getCommissionRates() {
      this.commissionRates = await commissionRateService.getCommissionRates();
      this.loading = false
    },
    populateCommissionRateEdit(commissionRate) {
      this.commissionRate = commissionRate
    },
    async addCommissionRate() {
      let response = await commissionRateService.createCommissionRate(this.commissionRate)
      if (response)
        this.commissionRateModal = false
        await this.getCommissionRates()
    },
    async updateCommissionRate(id) {
      let response = await commissionRateService.updateCommissionRate(this.commissionRate, id)
      if (response)
        this.commissionRateModal = false
        await this.getCommissionRates()
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getCommissionRates()
    if(this.$route.query.add)
      this.commissionRateModal = true
  },

  watch: {
    commissionRateModal: function () {
      if (!this.commissionRateModal) {
        this.commissionRate = {}
      }
    },
  }
}
</script>

<style scoped>

</style>
