<template>
  <div>
    <Card>
      <template slot="header">Casual Details</template>
      <div class="row">
        <div v-if="!loading" class="col-md-12">
          <h2 class="text-center">{{ name }}</h2>
        </div>
        <hr class="border-1 border-info border-opacity-75 mt-3" />
        <Nav nav-fill :init-active-tab="1">
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <NavItem title="Details">
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
            <div v-if="!loading" class="row">
              <div class="col-md-2">
                <img class="img-thumbnail bg-dark-subtle" width="100%" :src="avatar" alt="">
                <br>
                <button class="btn btn-primary mt-3 text-uppercase w-100" @click="$refs.refInputEl.click()">Upload Photo</button>
                <input
                    ref="refInputEl"
                    type="file"
                    name="logo"
                    accept=".jpeg,.png,.jpg"
                    hidden
                    @input="changeAvatar"
                >
              </div>
              <div class="col-md-10">
                <div class="col-md-12 row">
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Casual No</h6>
                    <p class="fst-italic">{{ casual.casual_no }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Address</h6>
                    <p class="fst-italic">{{ casual.address }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Phone</h6>
                    <p class="fst-italic">{{ casual.phone }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">ID Number</h6>
                    <p class="fst-italic">{{ casual.id_number }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Gender</h6>
                    <p class="fst-italic">{{ casual.gender }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Date of Birth</h6>
                    <p class="fst-italic">{{ formatDate(casual.dob) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Employment Date</h6>
                    <p class="fst-italic">{{ formatDate(casual.employment_date) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Daily Pay</h6>
                    <p class="fst-italic">{{ Ksh(casual.pay) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Termination Date</h6>
                    <p class="fst-italic">{{ !casual.termination_date ? 'N/A' : formatDate(casual.termination_date) }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Status</h6>
                    <p class="fst-italic">
                      <badge :type="getPillByStatus(casual.status)">{{ casual.status }}</badge>
                    </p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Department</h6>
                    <p class="fst-italic">{{ casual.department.name }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Work Station</h6>
                    <p class="fst-italic">{{ casual.station.name }}</p>
                  </div>
                  <div class="col-md-3 col-lg-2">
                    <h6 class="mt-3 fw-bold">Duty</h6>
                    <p class="fst-italic">{{ casual.duty }}</p>
                  </div>
                </div>
              </div>
            </div>
          </NavItem>
          <NavItem title="Advance">
            <div class="col-md-12">
              <div>
                <button
                    v-if="profile.role !== 'user'"
                    class="btn btn-success"
                    @click="advanceModal = true; advanceMode = 'add'">+ Add Advance
                </button>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Description</th>
                    <th scope="col">Created</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="advance in advances">
                    <td>{{ advance.name }}</td>
                    <td>{{ Ksh(advance.amount) }}</td>
                    <td>{{ advance.description }}</td>
                    <td>{{ formatDate(advance.created_at) }}</td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-primary btn-sm"
                          @click="advanceModal = true; advanceMode = 'edit'; populateAdvanceEdit(advance)"
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-danger btn-sm"
                          @click="confirmDialog('Advance', () => deleteAdvance(advance.id))"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </NavItem>
          <NavItem title="Deductions">
            <div class="col-md-12">
              <div>
                <button
                    v-if="profile.role !== 'user'"
                    class="btn btn-success"
                    @click="deductionModal = true; deductionMode = 'add'">+ Add Deduction
                </button>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Description</th>
                    <th scope="col">Created</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="deduction in deductions">
                    <td>{{ deduction.name }}</td>
                    <td>{{ Ksh(deduction.amount) }}</td>
                    <td>{{ formatDate(deduction.from) }}</td>
                    <td>{{ formatDate(deduction.to) }}</td>
                    <td>{{ deduction.description }}</td>
                    <td>{{ formatDate(deduction.created_at) }}</td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-primary btn-sm"
                          @click="deductionModal = true; deductionMode = 'edit'; populateDeductionEdit(deduction)"
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                          v-if="profile.role !== 'user'"
                          class="btn btn-danger btn-sm"
                          @click="confirmDialog('Deduction', () => deleteDeduction(deduction.id))"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </NavItem>
        </Nav>
        <hr class="border-1 border-info border-opacity-75 mt-3" />
        <div class="col-md-12 d-flex justify-content-center">
          <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-primary text-uppercase" @click="editCasual">Edit Casual</button>
        </div>
      </div>
    </Card>

    <!-- Add Advance Modal -->
    <Modal :open="advanceModal" @closed="advanceModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="advanceMode === 'add'">Add Advance</h5>
        <h5 class="modal-title m-0" v-else>Edit Advance</h5>
        <button @click="advanceModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" v-model="advance.name">
            </div>
            <div class="form-group col-md-12">
              <label for="amount" class="form-label fw-bold h5 mt-3">Amount: <span class="text-black-50 fst-italic h6">(daily advance)</span></label>
              <input type="number" class="form-control form-control-lg" v-model="advance.amount">
            </div>
            <div class="form-group col-md-12">
              <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" v-model="advance.description">
            </div>
            <div class="form-group col-md-12 mt-4">
              <div class="alert alert-info">
                <div class="content">
                  <h6 class="text-uppercase fw-bold">Advance Details:</h6>
                  <p class="mb-0">Ksh. {{ advance.amount }} added daily to Casuals' pay.</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="advanceModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="advanceMode === 'add'" @click="addAdvance">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateAdvance(advance.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Advance Modal End -->

    <!-- Add Deduction Modal -->
    <Modal :open="deductionModal" @closed="deductionModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="deductionMode === 'add'">Add Deduction</h5>
        <h5 class="modal-title m-0" v-else>Edit Deduction</h5>
        <button @click="deductionModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" v-model="deduction.name">
            </div>
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Amount: <span class="text-black-50 fst-italic h6">(daily deduction)</span></label>
              <input type="number" class="form-control form-control-lg" v-model="deduction.amount">
            </div>
            <div class="form-group col-md-6">
              <label for="from" class="form-label fw-bold h5 mt-3">From:</label>
              <input type="date" class="form-control form-control-lg" v-model="deduction.from">
            </div>
            <div class="form-group col-md-6">
              <label for="to" class="form-label fw-bold h5 mt-3">To:</label>
              <input type="date" class="form-control form-control-lg" v-model="deduction.to">
            </div>
            <div class="form-group col-md-12">
              <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" v-model="deduction.description">
            </div>
            <div class="form-group col-md-12 mt-4">
              <div class="alert alert-info">
                <div class="content">
                  <h6 class="text-uppercase fw-bold">Deduction Details:</h6>
                  <p class="mb-0">Ksh. {{ deduction.amount }} deducted daily for a period of <strong>{{ deductionDays }}</strong> days, totaling Ksh. <strong>{{ deductionDays * deduction.amount }}</strong></p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="deductionModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="deductionMode === 'add'" @click="addDeduction">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateDeduction(deduction.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Deduction Modal End -->

  </div>
</template>

<script>
import casualService from "@/api/casualService";
import formatDate from "../../utils/formatDate";
import Ksh from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";
import formatNumber from "../../utils/formatNumber";
import confirmDialog from "@/utils/confirmDialog";
import advanceService from "@/api/casualAdvanceService";
import deductionService from "@/api/casualDeductionService";

export default {
  name: "CasualDetails",
  data() {
    return {
      Ksh,
      profile: {},
      casual: {},
      avatarUrl: process.env.VUE_APP_API_IMAGE_URL + 'casual/',
      loading: true,
      advances: [],
      deductions: [],
      advanceMode: 'add',
      advanceModal:false,
      advance: {
        casual_id: this.$route.params.id,
        name: '',
        amount: '',
        description: '',
      },
      deductionMode: 'add',
      deductionModal:false,
      deduction: {
        casual_id: this.$route.params.id,
        name: '',
        amount: '',
        from: '',
        to: '',
        description: '',
      },
      deductionDays: 0
    }
  },
  methods: {
    formatDate,
    formatNumber,
    confirmDialog,
    async getCasual() {
      this.casual = await casualService.getCasual(this.$route.params.id)
      this.loading = false
    },
    editCasual() {
      this.$router.push('/edit-casual/' + this.$route.params.id);
    },
    async getAdvances() {
      this.advances = await advanceService.getAdvances(this.$route.params.id)
    },
    async getDeductions() {
      this.deductions = await deductionService.getDeductions(this.$route.params.id)
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
        case 'suspended':
          return 'warning';
        case 'terminated':
          return 'dark';
      }
    },
    changeAvatar : async function(file) {
      let fileReader = this.processFile(file)
      fileReader.onload = async () => {
        if (typeof fileReader.result === 'string')
          await casualService.updateAvatar({
            file: fileReader.result,
            casual_id: this.$route.params.id
          })
      }
    },
    processFile : function(file) {
      const fileReader = new FileReader()
      const { files } = file.target
      if (files && files.length) {
        fileReader.readAsDataURL(files[0])
        return fileReader
      }
    },
    async addAdvance() {
      let response = await advanceService.createAdvance(this.advance)
      if (response)
        this.advanceModal = false
        await this.getAdvances()
    },
    async updateAdvance(id) {
      let response = await advanceService.updateAdvance(this.advance, id)
      if (response)
        this.advanceModal = false
        await this.getAdvances()
    },
    async deleteAdvance(id) {
      await advanceService.deleteAdvance(id)
      await this.getAdvances()
    },
    populateAdvanceEdit(advance) {
      this.advance = advance
    },
    async addDeduction() {
      let response = await deductionService.createDeduction(this.deduction)
      if (response)
        this.deductionModal = false
      await this.getDeductions()
    },
    async updateDeduction(id) {
      let response = await deductionService.updateDeduction(this.deduction, id)
      if (response)
        this.deductionModal = false
      await this.getDeductions()
    },
    async deleteDeduction(id) {
      await deductionService.deleteDeduction(id)
      await this.getDeductions()
    },
    populateDeductionEdit(deduction) {
      this.deduction = deduction
    },
    calculateDays() {
      let from = new Date(this.deduction.from)
      let to = new Date(this.deduction.to)
      this.deductionDays = (to - from) / (1000 * 60 * 60 * 24) + 1
    }
  },

  async created() {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getCasual()
    await this.getAdvances()
    await this.getDeductions()
  },

  computed: {
    name() {
      let middle_name = this.casual.middle_name === null ? ' ' : this.casual.middle_name
      return this.casual.first_name + " " + middle_name + " " + this.casual.last_name
    },
    avatar() {
      return this.casual.avatar !== null ? this.avatarUrl + this.casual.avatar : 'https://villagesonmacarthur.com/wp-content/uploads/2020/12/Blank-Avatar.png'
    }
  },

  watch: {
    advanceModal: function () {
      if (!this.advanceModal) {
        this.advance = {
          casual_id: this.$route.params.id,
        }
      }
    },
    deductionModal: function () {
      if (!this.deductionModal) {
        this.deduction = {
          casual_id: this.$route.params.id,
        }
      }
    },
    'deduction.from': function () {
      this.calculateDays()
    },
    'deduction.to': function () {
      this.calculateDays()
    },
    'deduction.amount': function () {
      this.calculateDays()
    }
  }
}
</script>
