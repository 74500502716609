'use strict'
import api from '@/api/api'

export default {
    async getBanks() {
        const data = {
            method: 'get',
            url: 'banks',
        }

        let response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getBankNames() {
        const data = {
            method: 'get',
            url: 'bankNames',
        }

        let response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
}
