import {decrypt} from "@/utils/crypto";
import notify from "@/utils/notify";

export default function activityWatcher() {
    let logged_in = decrypt(sessionStorage.getItem('logged_in'))
    if (logged_in === 'true') {
        let secondsSinceLastActivity = 0
        let alertTime = (270)
        let maxInactivity = (300)
        let timeDiff = maxInactivity - alertTime
        setInterval(async function () {
            secondsSinceLastActivity++

            if (secondsSinceLastActivity > maxInactivity) {
                sessionStorage.clear();
                window.location.href = '/login'
            } else if (secondsSinceLastActivity === alertTime) {
                notify.alert.warning('User inactive. Logout in ' + timeDiff + ' seconds...', timeDiff * 1000)
            }
        }, 1000)

        function activity() {
            secondsSinceLastActivity = 0
        }

        let activityEvents = [
            'mousedown',
            'mousemove',
            'keydown',
            'scroll',
            'touchstart',
        ]

        activityEvents.forEach(function (eventName) {
            document.addEventListener(eventName, activity, true)
        })
    }
}
