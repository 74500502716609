<script>
import settingService from "@/api/settingService";
import formatNumber from "../../utils/formatNumber";

export default {
  name: "Settings",
  data() {
    return {
      loading: true,
      payslipLoading: false,
      simulateModal: false,
      payslip: {},
      simulate: {
        gross: 0,
        disability: false,
        paye: true,
        nhif: true,
        nssf: true,
        housing: true,
        nita: true,
      },
      settings: {
        settings: [],
        paye_rates: [],
        nhif_rates: [],
      },
    }
  },

  methods: {
    formatNumber,
    async simulatePayslip() {
      this.payslipLoading = true
      let response = await settingService.simulatePayslip(this.simulate)
      if (response.status === 200)
        this.payslip = response.data
        this.payslipLoading = false
    }
  },

  async created() {
    this.settings = await settingService.getSettings()
    this.loading = false
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              System Settings
            </div>
          </template>
          <div class="row">
            <div class="col-md-4 table-responsive">
              <h4 class="float-start me-3">NHIF Rates</h4>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">From</th>
                  <th scope="col">To</th>
                  <th scope="col">Amount</th>
                </tr>
                </thead>
                <tbody v-if="!loading">
                <tr v-for="nhif in settings.nhif_rates">
                  <td>{{ nhif.from }}</td>
                  <td>{{ nhif.to }}</td>
                  <td>{{ nhif.amount }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="loading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
            </div>
            <div class="col-md-4 table-responsive">
              <h4 class="float-start me-3">Settings</h4>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Value</th>
                </tr>
                </thead>
                <tbody v-if="!loading">
                <tr v-for="setting in settings.settings">
                  <td>{{ setting.name }}</td>
                  <td>{{ setting.value }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="loading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
            </div>
            <div class="col-md-4 table-responsive">
              <h4 class="float-start me-3">PAYE Rates</h4>
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">Month</th>
                  <th scope="col">Year</th>
                  <th scope="col">Rate(%)</th>
                </tr>
                </thead>
                <tbody v-if="!loading">
                <tr v-for="paye in settings.paye_rates">
                  <td>{{ paye.band_month }}</td>
                  <td>{{ paye.band_year }}</td>
                  <td>{{ paye.rate }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="loading" class="text-center">
                <Spinner spinner-class="mb-1" />
              </div>
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button class="btn btn-success text-uppercase" @click="simulateModal = true">Simulate Payslip</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add User Modal -->
    <Modal :open="simulateModal" @closed="simulateModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0">Simulate Payslip</h5>
        <button @click="simulateModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-2">
              <label class="form-label fw-bold h6 mt-3">Gross Income:</label>
            </div>
            <div class="form-group col-md-3">
              <input type="number" class="form-control" v-model="simulate.gross">
            </div>
          </div>
          <br>
          <div class="row">
            <div class="form-group col-md-2">
              <label class="form-label fw-bold h6 mt-3">Disability:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="simulate.disability">
            </div>
            <div class="form-group col-md-2">
              <label class="form-label fw-bold h6 mt-3">PAYE:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="simulate.paye">
            </div>
            <div class="form-group col-md-2">
              <label class="form-label fw-bold h6 mt-3">NHIF:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="simulate.nhif">
            </div>
            <div class="form-group col-md-2">
              <label class="form-label fw-bold h6 mt-3">NSSF:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="simulate.nssf">
            </div>
            <div class="form-group col-md-2">
              <label class="form-label fw-bold h6 mt-3">Housing Levy:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="simulate.housing">
            </div>
            <div class="form-group col-md-2">
              <label class="form-label fw-bold h6 mt-3">NITA:</label>
              <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="simulate.nita">
            </div>
          </div>
        </form>
        <hr class="border-1 border-info border-opacity-75 mt-3" />
        <div v-if="!payslipLoading" class="row">
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">Gross Income:</label>
            <p>{{ formatNumber(payslip.gross) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">Taxable Income:</label>
            <p>{{ formatNumber(payslip.taxable) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">P.A.Y.E:</label>
            <p>{{ formatNumber(payslip.tax) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">NHIF:</label>
            <p>{{ formatNumber(payslip.nhif) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">NSSF:</label>
            <p>{{ formatNumber(payslip.nssf) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">NHIF Relief:</label>
            <p>{{ formatNumber(payslip.nhif_relief) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">Tax Relief:</label>
            <p>{{ formatNumber(payslip.tax_relief) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">Housing Levy:</label>
            <p>{{ formatNumber(payslip.housing_levy) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">NITA:</label>
            <p>{{ formatNumber(payslip.nita) }}</p>
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold h5 mt-3">Net Income:</label>
            <p>{{ formatNumber(payslip.net) }}</p>
          </div>
        </div>
        <div v-else class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="simulateModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" @click="simulatePayslip">Submit</button>
      </div>
    </Modal>
    <!-- Add User Modal End -->

  </div>
</template>

<style scoped>

</style>
