'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";
import {encrypt} from "@/utils/crypto";

export default {
    async getCasuals() {
        const data = {
            method: 'get',
            url: 'casuals',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            sessionStorage.setItem('casualsNo', encrypt(JSON.stringify(response.data.length)))
            return response.data
        }
    },
    async getCasualNextNo() {
        const data = {
            method: 'get',
            url: 'casualNextNo',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getCasual(id) {
        const data = {
            method: 'get',
            url: 'casual/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createCasual(json) {
        const data = {
            method: 'post',
            url: 'casual',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Casual added successfully')
            router.push('/casual/' + response.data.id)
        }
    },
    async updateCasual(json, id) {
        const data = {
            method: 'patch',
            url: 'casual/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Casual updated successfully')
            router.push('/casual/' + id)
        }
    },
    async updateAvatar(json) {
        const data = {
            method: 'post',
            url: 'casual/avatar',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Casual picture updated successfully')
            window.location.reload()
        }
    },
}
