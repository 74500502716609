import { useToast } from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const $toast = useToast()

const alert = {
  success: (message, duration = 7000) => {
    $toast.open({
      message: message,
      type: 'success',
      position: 'top',
      duration: duration,
    })
  },
  error: (message, duration = 7000) => {
    $toast.open({
      message: message,
      type: 'error',
      position: 'top',
      duration: duration,
    })
  },
  warning: (message, duration = 7000) => {
    $toast.open({
      message: message,
      type: 'warning',
      position: 'top',
      duration: duration,
    })
  },
  info: (message, duration = 7000) => {
    $toast.open({
      message: message,
      type: 'info',
      position: 'top',
      duration: duration,
    })
  },
  default: (message, duration = 7000) => {
    $toast.open({
      message: message,
      position: 'top',
      duration: duration,
    })
  },
}

export default {
  alert,
}