<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                System Logs ({{logs.data.length}})
                <span class="pb-5">
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12">
            <div class="row bg-info rounded shadow-lg p-2">
              <div class="form-group col-md-2">
                <label class="form-label fw-bold text-white h6">User:</label>
                <select data="" type="text" class="form-select" v-model="search.causer_id">
                  <option value="" selected>All users</option>
                  <option v-for="user in users" :key="user.id" :value="user.id">{{user.first_name}} {{user.last_name}}</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label class="form-label fw-bold text-white h6">Module:</label>
                <select data="" type="text" class="form-select" v-model="search.log_name">
                  <option value="" selected>All modules</option>
                  <option v-for="module in modules" :key="module.id" :value="module.module">{{module.name}}</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label class="form-label fw-bold text-white h6">Action:</label>
                <select data="" type="text" class="form-select" v-model="search.event">
                  <option value="" selected>All actions</option>
                  <option value="created">Created</option>
                  <option value="updated">Updated</option>
                  <option value="deleted">Deleted</option>
                  <option value="login">Login</option>
                  <option value="logout">Logout</option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label class="form-label fw-bold text-white h6">From:</label>
                <input type="date" class="form-control" v-model="search.from">
              </div>
              <div class="form-group col-md-2">
                <label class="form-label fw-bold text-white h6">To:</label>
                <input type="date" class="form-control" v-model="search.to">
              </div>
              <div class="form-group col-md-2" style="padding-top: 30px">
                <button class="btn btn-warning" @click="resetFilter">Reset Filter</button>
              </div>
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-sm">
              <thead>
              <tr>
                <th scope="col">User</th>
                <th scope="col">Module</th>
                <th scope="col">Action</th>
                <th scope="col">Description</th>
                <th scope="col">Date</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="log in logs.data">
                <td>{{ log.description }}</td>
                <td>{{ log.log_name }}</td>
                <td>{{ log.event }}</td>
                <td>{{ log.event }} {{ log.log_name }}</td>
                <td>{{ log.created_at.replace("T", ' ').replace(".000000Z", '') }}</td>
                <td>
                  <button
                      class="btn btn-info btn-sm"
                      @click="logModal = true; populateLog(log)"
                  >
                    View
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-between">
            <div>
              <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            </div>
            <div class="d-flex align-items-center col-lg-2">
              <span class="col-lg-4"><strong>Per Page:</strong></span>
              <select data="" class="form-select form-select-md" v-model="search.per_page">
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div>
              <Pagination :pages="logs.last_page" v-model="search.page">
                <span slot="next">Next</span>
                <span slot="previous">Prev</span>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <!-- Show Log Modal -->
    <Modal :open="logModal" dialog-size="modal-lg" @closed="logModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0">Log Information</h5>
        <button @click="logModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="row">
            <div class="col-md-12">
              <span class="form-label fw-bold h6 mt-3">User: </span>
              <span>{{ log.description }}</span>
            </div>
            <div class="col-md-12">
              <span class="form-label fw-bold h6 mt-3">Description: </span>
              <span>{{ log.event + ' ' + log.log_name }}</span>
            </div>
            <div class="col-md-12">
              <span class="form-label fw-bold h6 mt-3">Date: </span>
              <span>{{ log.created_at.replace("T", ' ').replace(".000000Z", '') }}</span>
            </div>
            <div class="col-md-12">
              <br>
              <span class="form-label fw-bold h6 mt-3">Data: </span>
              <span>{{ log.properties }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="logModal = false">Close</button>
      </div>
    </Modal>
    <!-- Show Log Modal End -->

  </div>
</template>

<script>

import settingService from "@/api/settingService";
import userService from "@/api/userService";
import {encrypt, decrypt} from "@/utils/crypto";

export default {
  name: "Logs",

  data() {
    return {
      loading: true,
      logs: {
        data: []
      },
      log: {},
      users: [],
      modules: [],
      logModal: false,
      search: {
        page: 1,
        per_page: 10,
        log_name: '',
        causer_id: '',
        event: '',
        from: '',
        to: '',
      },
    }
  },

  methods: {
    populateLog(log) {
      this.log = log
    },
    async resetFilter() {
      window.location.reload()
    },
    async getLogs() {
      this.loading = true
      this.logs = await settingService.getLogs(this.search)
      this.loading = false
    },
    async getUsersAndModules() {
      if (sessionStorage.getItem('users')) {
        this.modules = JSON.parse(decrypt(sessionStorage.getItem('modules')))
        this.users = JSON.parse(decrypt(sessionStorage.getItem('users')))
      } else {
        this.users = await userService.getUsers()
        this.modules = await settingService.getModules()
        sessionStorage.setItem('users', encrypt(JSON.stringify(this.users)))
        sessionStorage.setItem('modules', encrypt(JSON.stringify(this.modules)))
      }
    }
  },

  async created() {
    await this.getLogs()
    await this.getUsersAndModules()
  },

  watch: {
    search: {
      async handler(val) {
        await this.getLogs()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
