<template>
  <Card>
    <template slot="header">My Profile</template>
    <div class="row">
      <div class="col-md-12">
        <h2 v-if="!loading" class="text-center">{{ user.first_name }} {{ user.last_name }}</h2>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div class="col-md-12 row">
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
        <div class="col-md-4">
          <img class="img-thumbnail bg-dark-subtle" width="150" :src="avatarUrl + user.avatar" alt="">
          <br>
          <button class="btn btn-primary mt-3 text-uppercase" @click="$refs.refInputEl.click()">Upload Photo</button>
          <input
              ref="refInputEl"
              type="file"
              name="logo"
              accept=".jpeg,.png,.jpg"
              hidden
              @input="changeAvatar"
          >
        </div>
        <div v-if="!loading" class="col-md-8 row">
          <div class="col-md-6">
            <h5 class="mt-3 fw-bold">Phone</h5>
            <p class="fst-italic">{{ user.phone }}</p>
            <h5 class="mt-3 fw-bold">Email address</h5>
            <p class="fst-italic">{{ user.email }}</p>
          </div>
          <div class="col-md-6">
            <h5 class="mt-3 fw-bold">Role</h5>
            <p class="fst-italic">{{ user.role }}</p>
            <h5 class="mt-3 fw-bold">Created</h5>
            <p class="fst-italic">{{ formatDate(user.created_at) }}</p>
          </div>
        </div>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div class="col-md-12 d-flex justify-content-center">
        <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
        <button class="btn btn-primary text-uppercase" @click="showModal = true">Edit</button>
      </div>
    </div>

    <div class="col-md-12 mt-5">
      <Card class="">
        <div class="modal-body p-1">
          <div class="font-size-150 fw-bold">
            CHANGE PASSWORD
          </div>
          <div class="mt-4">
            <form @submit.prevent="">
              <div class="row">
                <div class="col-md-6">
                  <UInput v-model="userPass.password" placeholder="Password" type="password">
                    <template #label>New Password</template>
                  </UInput>
                </div>
                <div class="col-md-6">
                  <UInput v-model="userPass.password_confirmation" placeholder="Password" type="password">
                    <template #label>Confirm Password</template>
                  </UInput>
                </div>
              </div>
              <hr class="border-1 border-info border-opacity-75 mt-3" />
              <div class=" mt-4 d-flex flex-row justify-content-center align-items-center">
                <Button class="text-uppercase" @click="updatePassword">Update Password</Button>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>

    <!-- Edit Profile Modal -->
    <Modal :open="showModal" @closed="showModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0">Edit Profile</h5>
        <button @click="showModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="first_name" class="form-label fw-bold h5 mt-3">First Name:</label>
              <input type="text" class="form-control form-control-lg" v-model="user.first_name">
            </div>
            <div class="form-group col-md-6">
              <label for="last_name" class="form-label fw-bold h5 mt-3">Last Name:</label>
              <input type="text" class="form-control form-control-lg" v-model="user.last_name">
            </div>
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Phone:</label>
              <input type="text" class="form-control form-control-lg" v-model="user.phone">
            </div>
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Email:</label>
              <input type="text" class="form-control form-control-lg" v-model="user.email">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="showModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
      </div>
    </Modal>
    <!-- Edit Profile Modal End -->

  </Card>
</template>

<script>
import userService from "@/api/userService";
import formatDate from "../../utils/formatDate";

export default {
  name: "UserProfile",
  data() {
    return {
      loading: true,
      showModal: false,
      user: {},
      userPass: {
        password: '',
        password_confirmation: ''
      },
      avatarUrl: process.env.VUE_APP_API_IMAGE_URL + 'user/',
    }
  },
  methods: {
    formatDate,
    submitForm: async function () {
      let response = await userService.updateProfile({
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
        email: this.user.email
      })
      if (response)
        this.showModal = false
    },
    async updatePassword() {
      await userService.changePassword(this.userPass, this.user.id)
    },
    changeAvatar : async function(file) {
      const fileReader = new FileReader()
      const { files } = file.target
      if (files && files.length) {
        fileReader.readAsDataURL(files[0])
        fileReader.onload = async () => {
          if (typeof fileReader.result === 'string')
            await userService.updateAvatar({ file: fileReader.result })
        }
      }
    }
  },
  created: async function () {
    this.user = await userService.getProfile()
    this.loading = false
  }
}
</script>
