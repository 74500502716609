<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Casual Payrolls ({{payrollData.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user'" class="btn btn-success btn-sm" @click="payrollModal = true">+ Add Casual Payroll</button>
                </span>
              </span>
              <UInput v-model="dataTableSearch" size="md" placeholder="Search payroll" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="payrollData" :headers="dataTableHeaders"
                        :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row}">
                <Cell v-for="(cellData, key) in row">
                  <Badge v-if="key.toString().toLowerCase() === 'status' || key === 6" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else>{{cellData}}</span>
                </Cell>
                <Cell>
                  <Button class="btn-info btn-sm" @click="payrollDetails(row[0])">View Payroll</Button>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Payroll Modal -->
    <Modal :open="payrollModal" @closed="payrollModal = false" dialog-size="modal-sm">
      <div class="modal-header">
        <h5 class="modal-title m-0">Add Casual Payroll</h5>
        <button @click="payrollModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
              <input type="text" class="form-control form-control-lg" v-model="payroll.code" readonly>
            </div>
            <div class="form-group col-md-12">
              <label for="from" class="form-label fw-bold h5 mt-3">From:</label>
              <input type="date" class="form-control form-control-lg" v-model="payroll.from">
            </div>
            <div class="form-group col-md-12">
              <label for="to" class="form-label fw-bold h5 mt-3">To:</label>
              <input type="date" class="form-control form-control-lg" v-model="payroll.to">
            </div>
            <div class="form-group col-md-12">
              <label for="to" class="form-label fw-bold h5 mt-3">Days:</label>
              <input type="number" class="form-control form-control-lg" v-model="payroll.days" readonly>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="payrollModal = false">Cancel</button>
        <button v-if="profile.role !== 'user'" type="button" class="btn btn-primary text-uppercase" @click="addPayroll">Submit</button>
      </div>
    </Modal>
    <!-- Add Payroll Modal End -->

  </div>
</template>

<script>
import casualPayrollService from "@/api/casualPayrollService";
import formatDate from "@/utils/formatDate";
import router from "@/router";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Casual Payroll",

  data(){
    return {
      profile: {},
      dataTableHeaders: [
        '#',
        'Payroll No',
        'Run By',
        'From',
        'To',
        'Days',
        'Status',
        'Created',
        null
      ],
      loading: true,
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 10,
      payrollModal: false,
      payroll: {
        code: '',
        from: '',
        to: '',
        days: 0,
      },
      nextNo: {},
    }
  },

  methods: {
    payrollDetails(id) {
      router.push('/payroll/casual/' + id)
    },
    async getPayrolls() {
      this.dataTable = await casualPayrollService.getPayrolls()
      this.nextNo = await casualPayrollService.getPayrollNextNo()
      this.payroll.code = this.nextNo.next
      this.loading = false
    },
    async addPayroll() {
      let response = await casualPayrollService.createPayroll(this.payroll)
      if (response)
        this.payrollModal = false
        await this.getPayrolls()
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'draft':
          return 'danger';
        case 'confirmed':
          return 'success';
      }
    },
    calculateDays() {
      let from = new Date(this.payroll.from)
      let to = new Date(this.payroll.to)
      this.payroll.days = (to - from) / (1000 * 60 * 60 * 24) + 1
    }
  },

  computed: {
    payrollData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          this.dataTable[i].code,
          this.dataTable[i].user.first_name + ' ' + this.dataTable[i].user.last_name,
          formatDate(this.dataTable[i].from),
          formatDate(this.dataTable[i].to),
          this.dataTable[i].days,
          this.dataTable[i].status,
          formatDate(this.dataTable[i].created_at)
        ];
        data.push(fields);
      }
      return data;
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getPayrolls()
  },

  watch: {
    'payroll.from': function () {
      this.calculateDays()
    },
    'payroll.to': function () {
      this.calculateDays()
    }
  }

}
</script>

<style scoped>

</style>
