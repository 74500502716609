'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import organizationService from "@/api/organizationService";

export default {
    async getTemplates() {
        const data = {
            method: 'get',
            url: 'templates',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getTemplate(id) {
        const data = {
            method: 'get',
            url: 'template/' + id,
        }

        return await api.api(data)
    },
    async setTemplate(id) {
        const data = {
            method: 'post',
            url: 'template/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Template successfully changed')
            await organizationService.getOrganization()
        }
    },
    async updateColors(json) {
        const data = {
            method: 'patch',
            url: 'colors',
            body: json
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Template colors successfully changed')
            await organizationService.getOrganization()
        }
    },
    async updateIntegration(json) {
        const data = {
            method: 'post',
            url: 'integration',
            body: json
        }

        const response = await api.api(data)
        if (response.status === 200 || response.status === 201) {
            notify.alert.success('Bank Integration successfully updated')
            await organizationService.getOrganization()
            return true
        }
    },
}
