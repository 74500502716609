<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Payouts ({{payouts.length}})
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Payslip#</th>
                <th scope="col">Employee no</th>
                <th scope="col">Account name</th>
                <th scope="col">Account no</th>
                <th scope="col">Bank</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="payout in payouts">
                <td><a :href="`/payslip/${payout.payslip_id}`">{{ payout.payslip_id.split("-").pop() }}</a></td>
                <td>{{ payout.employee.employee_no }}</td>
                <td><a :href="`/employee/${payout.employee.id}`">{{ payout.name }}</a></td>
                <td>{{ payout.account }}</td>
                <td>{{ payout.bank }}</td>
                <td>{{ formatNumber(payout.amount) }}</td>
                <td><Badge pill :type="getPillByStatus(payout.status)">{{ payout.status }}</Badge></td>
                <td>{{ formatDate(payout.created_at) }}</td>
                <td>
                  <button
                      class="btn btn-info btn-sm"
                      @click="payoutModal = true; populatePayout(payout)"
                  >
                    Details
                  </button>
                  <button
                      v-if="payout.status === 'failed'"
                      class="btn btn-danger btn-sm ms-3"
                      @click="confirmDialog('Retry Payout', () => retryPayout(payout.id), 'Do you want to retry this payout?')"
                  >
                    Retry
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Show Payout Modal -->
    <Modal :open="payoutModal" dialog-size="modal-lg" @closed="payoutModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0">Payout Information</h5>
        <button @click="payoutModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="row">
            <div class="col-md-12">
              <span class="form-label fw-bold h6 mt-3">Account name: </span>
              <span>{{ payout.name }}</span>
            </div>
            <div class="col-md-12">
              <span class="form-label fw-bold h6 mt-3">Account no: </span>
              <span>{{ payout.account }}</span>
            </div>
            <div class="col-md-12">
              <span class="form-label fw-bold h6 mt-3">Date: </span>
              <span>{{ payout.created_at.replace("T", ' ').replace(".000000Z", '') }}</span>
            </div>
            <div class="col-md-12">
              <br>
              <span class="form-label fw-bold h6 mt-3">Data: </span>
              <span>{{ payout.meta }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="payoutModal = false">Close</button>
      </div>
    </Modal>
    <!-- Show Payout Modal End -->

  </div>
</template>

<script>

import payoutService from "@/api/payoutService";
import formatDate from "@/utils/formatDate";
import formatNumber from "@/utils/formatNumber";
import confirmDialog from "@/utils/confirmDialog";

export default {
  name: "PayrollPayouts",

  data() {
    return {
      payouts: [],
      payout: {},
      loading: true,
      payoutModal: false,
    }
  },

  methods: {
    formatDate,
    formatNumber,
    confirmDialog,
    populatePayout(payout) {
      this.payout = payout
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'submitted':
          return 'dark';
        case 'processing':
          return 'warning';
        case 'paid':
          return 'success';
        case 'failed':
          return 'danger';
      }
    },
    async retryPayout(id) {
      await payoutService.retryPayout(id)
    }
  },

  async created() {
    this.payouts = await payoutService.getPayrollPayouts(this.$route.params.id)
    this.loading = false
  }
}
</script>

<style scoped>

</style>
