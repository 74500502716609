<template>
  <Card>
    <template slot="header">Organization Details</template>
    <div class="row">
      <div v-if="loading" class="text-center">
        <Spinner spinner-class="mb-1" />
      </div>
      <div class="col-md-12">
        <h2 class="text-center">Payment Integrations</h2>
      </div>
      <hr class="border-1 border-info border-opacity-75 mt-3" />
      <div v-if="!loading" class="col-md-12">
        <div class="col-md-12" v-if="!organization.integrations?.length">
          <h5 class="mb-2">Status: <Badge type="danger">Inactive</Badge></h5>
          <h5>Please contact us for activation</h5>
        </div>
        <div v-else>
          <div v-for="integration in organization.integrations" :key="integration.id">
            <Card class="row mb-3">
              <div class="col-md-12">
                <h3>Name: <strong>{{ integration.type.toUpperCase() }}</strong></h3>
                <h5>Status: <Badge type="success">{{ integration.status }}</Badge></h5>
              </div>
              <div class="col-md-12 row mt-3">
                <div v-if="integration.type === 'bank'" class="col-md-6 col-lg-3">
                  <h6 class="mt-1 fw-bold">Account number</h6>
                  <p class="fst-italic">{{ organization.account_no }}</p>
                </div>
                <div v-if="integration.type === 'bank'" class="col-md-6 col-lg-3">
                  <h6 class="mt-1 fw-bold">Branch name</h6>
                  <p class="fst-italic">{{ organization.branch_name }}</p>
                </div>
                <div v-if="integration.type === 'bank'" class="col-md-6 col-lg-3">
                  <h6 class="mt-1 fw-bold">Bank code</h6>
                  <p class="fst-italic">{{ organization.bank_code }}</p>
                </div>
                <div v-if="integration.type === 'bank'" class="col-md-6 col-lg-3">
                  <h6 class="mt-1 fw-bold">Branch code</h6>
                  <p class="fst-italic">{{ organization.branch_code }}</p>
                </div>
                <div v-for="(value, key) in integration.meta" class="col-md-6 col-lg-3">
                  <h6 class="mt-1 fw-bold">{{ key }}</h6>
                  <p v-if="key === 'initiator_password'" class="fst-italic">***************</p>
                  <p v-else class="fst-italic">{{ value }}</p>
                </div>
                <div class="col-md-6 col-lg-3">
                  <h6 class="mt-1 fw-bold">Activated on</h6>
                  <p class="fst-italic">{{ formatDate(integration.created_at) }}</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>

import {decrypt} from "@/utils/crypto";
import formatDate from "@/utils/formatDate";

export default {
  name: "Integration",

  data() {
    return {
      loading: true,
      organization: {
        integrations: []
      }
    }
  },

  methods: {
    formatDate,
    getOrganization() {
      this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
      this.loading = false;
    }
  },

  mounted() {
    this.getOrganization();
  },
}
</script>

<style scoped>

</style>
