<template>
  <!-- App -->
    <div id="app">
        <DialogsWrapper />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
export default {
    name: "app"
};
</script>
