<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Designations ({{designations.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-success btn-sm" @click="designationModal = true; designationMode = 'add'">+ Add Designation</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="designation in designations">
                <td>{{ designation.code }}</td>
                <td>{{ designation.name }}</td>
                <td>{{ designation.description }}</td>
                <td>{{ formatDate(designation.created_at) }}</td>
                <td>
                  <button
                      v-if="profile.role !== 'user' && profile.role !== 'admin'"
                      class="btn btn-info btn-sm"
                      @click="designationModal = true; designationMode = 'edit'; populateDesignationEdit(designation)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Designation Modal -->
    <Modal :open="designationModal" @closed="designationModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="designationMode === 'add'">Add Designation</h5>
        <h5 class="modal-title m-0" v-else>Edit Designation</h5>
        <button @click="designationModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Designation code" v-model="designation.code" readonly>
            </div>
            <div class="form-group col-md-12">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Designation name" v-model="designation.name">
            </div>
            <div class="form-group col-md-12">
              <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" placeholder="Designation description" v-model="designation.description">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="designationModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="designationMode === 'add'" @click="addDesignation">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateDesignation(designation.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Designation Modal End -->

  </div>
</template>

<script>
import designationService from "@/api/designationService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Designations",

  data(){
    return {
      profile: {},
      loading: true,
      designations: [],
      designationMode: 'add',
      designationModal: false,
      designation: {
        code: '',
        name: '',
        description: '',
      },
      nextNo: {},
    }
  },

  methods: {
    formatDate,
    async getDesignations() {
      this.designations = await designationService.getDesignations()
      this.nextNo = await designationService.getDesignationNextNo()
      this.designation.code = this.nextNo.next
      this.loading = false
    },
    populateDesignationEdit(designation) {
      this.designation = designation
    },
    async addDesignation() {
      let response = await designationService.createDesignation(this.designation)
      if (response)
        this.designationModal = false
        await this.getDesignations()
    },
    async updateDesignation(id) {
      let response = await designationService.updateDesignation(this.designation, id)
      if (response)
        this.designationModal = false
        await this.getDesignations()
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getDesignations()
    if(this.$route.query.add)
      this.designationModal = true
  },

  watch: {
    designationModal: function () {
      if (!this.designationModal) {
        this.designation = {
          code: this.nextNo.next,
        }
      }
    },
  }

}
</script>

<style scoped>

</style>
