<template xmlns="http://www.w3.org/1999/html">
  <div class="text-center row">
    <div class="col-md-6 offset-md-3 mt-5">
      <div v-if="loading" class="text-center">
        <h5>Verifying payslip...</h5>
        <Spinner spinner-class="mb-1" />
      </div>
      <div v-else class="card">
        <div v-if="valid">
          <h3 class="mt-4"><strong>Name:</strong> {{ employee.name }}</h3>
          <h3><strong>ID No:</strong> {{ employee.id_number }}</h3>
          <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:30px auto;">
            <i class="checkmark">✓</i>
          </div>
          <h1>Payslip Verified</h1>
          <p>This payslip is valid and exists in our system.</p>
        </div>
        <div v-else>
          <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:30px auto;">
            <i class="closemark">✕</i>
          </div>
          <h1>Payslip Invalid</h1>
          <p>This payslip is invalid and does not exists in our system.</p>
        </div>
        <br>
        <button class="btn btn-lg btn-dark text-uppercase" @click="reVerify">Verify Again</button>
      </div>
    </div>
  </div>
</template>

<script>

import payrollService from "@/api/payrollService";

export default {
  name: "VerifyPayslip",

  data() {
    return {
      id: this.$route.params.id,
      employee: {},
      loading: true,
      valid: false
    }
  },

  async created() {
    await this.verifyPayslip()
  },

  methods: {
    async verifyPayslip() {
      let response = await payrollService.verifyPayslip(this.id)
      if (response.status === 200) {
        this.employee = response.data
        this.valid = true
      }
      this.loading = false
    },
    reVerify() {
      window.location.reload()
    }
  }
}
</script>

<style scoped>
.checkmark {
  color: #4BB543;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.closemark {
  color: red;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
</style>
