'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getEmployeeCommissions(id) {
        const data = {
            method: 'get',
            url: 'employee-commissions/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        } else {
            return null
        }
    },
    async createEmployeeCommission(json) {
        const data = {
            method: 'post',
            url: 'employee-commission',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Employee commission created successfully')
            return true;
        }
    },
    async updateEmployeeCommission(json, id) {
        const data = {
            method: 'patch',
            url: 'employee-commission/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employee commission updated successfully')
            return true;
        }
    },
    async deleteEmployeeCommission(id) {
        const data = {
            method: 'delete',
            url: 'employee-commission/' + id
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employee commission deleted successfully')
            return true;
        }
    },
    async getCommissionRate(id) {
        let employeeCommission = await this.getEmployeeCommissions(id);
        if (employeeCommission !== null) {
            const data = {
                method: 'get',
                url: 'commission-rate/' + employeeCommission.commission_rate_id,
            }

            const response = await api.api(data)
            if (response.status === 200) {
                return response.data
            }
        } else {
            return null
        }
    },
}
