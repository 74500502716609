<script>
import payrollService from "@/api/payrollService";
import {decrypt} from "@/utils/crypto";
import notify from "@/utils/notify";

export default {
  name: "Payslip",
  data() {
    return {
      profile: {},
      loading: true,
      payslip: {
        payroll: {},
        employee: {},
      },
      payslipHtml: '',
      payslipUrl: process.env.VUE_APP_API_IMAGE_URL + 'payslips/',
      payslipName: '',
    }
  },
  methods: {
    async emailPayslip() {
      await payrollService.emailPayslip(this.$route.params.id)
    },
    async downloadPayslip() {
      notify.alert.success('Generating and downloading payslip...')
      let response = await payrollService.generatePayslip(this.$route.params.id)
      if (response.available === "true") {
        window.open(this.payslipUrl + this.payslipName, '_blank')
      } else {
        setTimeout(() => {
          window.open(this.payslipUrl + this.payslipName, '_blank')
        }, 4000);
      }
    }
  },

  computed: {
    payrollStatus() {
      return localStorage.getItem('payrollStatus') !== 'draft' && this.profile.role !== 'user'
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.payslip = await payrollService.getPayslip(this.$route.params.id)
    this.payslipHtml = await payrollService.getPayslipHtml(this.$route.params.id)
    this.loading = false
    this.payslipName = this.payslip.employee.first_name + '-Payroll-' + this.payslip.payroll.month + '-' + this.payslip.id + '.pdf';
  },

}
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 justify-content-center">
        <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
        <button v-if="payrollStatus" class="btn btn-primary text-uppercase me-4" @click="downloadPayslip">Download</button>
        <button v-if="payrollStatus" class="btn btn-info text-uppercase" @click="emailPayslip">Send to Email</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
        <div v-else>
          <div v-html="payslipHtml.html">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
