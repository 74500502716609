<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <div v-if="subscription.package.casuals <= casualData.length" role="alert" class="alert alert-info">
            <div class="content">
              Your subscription has reached the limit of {{ subscription.package.casuals }} casuals.
              <button class="btn btn-sm btn-dark text-uppercase ms-3" @click="$router.push('upgrade')">Upgrade</button>
            </div>
          </div>
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Casuals ({{casualData.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user'" class="btn btn-success btn-sm" :class="subscription.package.casuals <= casualData.length ? 'disabled' : ''" @click="addCasual">+ Add Casual</button>
                  <button v-if="casualData.length === 0 && !loading && profile.role === 'primary'" class="btn btn-sm ms-3 text-uppercase" @click="$refs.refInputEl.click()"></button>
                    <input
                        ref="refInputEl"
                        type="file"
                        name="logo"
                        accept=".xlsx,.xls"
                        hidden
                        @input="uploadCasualFile"
                    >
                </span>
              </span>
              <UInput v-model="dataTableSearch" size="md" placeholder="Search casual" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="casualData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row, rowId}">
                <Cell
                    v-for="(cellData, key) in row"
                    :cell-classes="[
                        // Center
                        ['selected', 'status'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'status' || key === 5" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else>{{cellData}}</span>
                </Cell>
                <Cell>
                  <Button class="btn-info btn-sm" @click="casualDetails(rowId)">Details</Button>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import casualService from "@/api/casualService";
import formatDate from "@/utils/formatDate";
import router from "@/router";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Casuals",

  data(){
    return {
      profile: {},
      loading: true,
      dataTableHeaders: [
        '#',
        'Casual No',
        'Name',
        'ID Number',
        'Phone',
        'Status',
        'Created',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 10,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
        case 'suspended':
          return 'warning';
        case 'terminated':
          return 'dark';
      }
    },
    async getCasuals() {
      this.dataTable = await casualService.getCasuals();
      this.loading = false
    },
    casualDetails(id) {
      router.push('/casual/' + id)
    },
    addCasual(){
      router.push('/add-casual')
    },
    uploadCasualFile : async function(file) {
      const fileReader = new FileReader()
      const { files } = file.target
      let response = false
      if (files && files.length) {
        fileReader.readAsDataURL(files[0])
        fileReader.onload = async () => {
          if (typeof fileReader.result === 'string')
            response = await casualService.uploadCasualFile({ file: fileReader.result })
          if (response)
            await this.getCasuals()
        }
      }
    }
  },

  computed: {
    casualData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          this.dataTable[i].casual_no,
          this.dataTable[i].first_name + ' ' + this.dataTable[i].last_name,
          this.dataTable[i].id_number,
          this.dataTable[i].phone,
          this.dataTable[i].status,
          formatDate(this.dataTable[i].created_at)
        ];
        data.push(fields);
      }
      return data;
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getCasuals()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

}
</script>

<style scoped>

</style>
