<template>
  <div class="confirm-container fixed-top">
    <Card>
      <div class="row justify-content-center p-2" style="max-width: 330px">
        <div class="modal-body col-md-6">
          <div class="d-flex justify-content-between mt-2">
            <h5><strong>{{ title }}</strong></h5>
            <h5 class="cursor-pointer" @click="emit('cancel')"><strong>x</strong></h5>
          </div>
          <hr class="mb-4">
          <h5 class="mb-5 text-center">{{ message }}?</h5>
          <div class="d-flex justify-content-between">
            <button class="btn bg-dark-subtle" @click="emit('cancel')">Cancel</button>
            <button class="btn btn-secondary" @click="emit('confirm')">Confirm</button>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup>

const props = defineProps(['title', 'message'])
const emit = defineEmits(['confirm', 'cancel'])
</script>

<style>
.confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.71);
}
</style>