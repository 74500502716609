<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
        <div v-else class="content">
          <div v-html="template.html">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import accountService from "@/api/accountService";
import notify from "@/utils/notify";

export default {
  name: "TemplatePreview",

  data() {
    return {
      template: {},
      loading: true
    }
  },

  async created() {
    let response = await accountService.getTemplate(this.$route.params.id)
    if (response.status === 200) {
      this.template = response.data
    } else {
      notify.alert.info('You need to have active payrolls to preview templates. Run Payroll first.')
    }
    this.loading = false
  }
}
</script>

<style scoped>

</style>
