'use strict'
import { default as axios } from 'axios'
import notify from "@/utils/notify";
import {decrypt} from "@/utils/crypto";

export default {

  async api(data, token = null) {
    if (token === null) {
      token = decrypt(sessionStorage.getItem('access_token'))
    }
    const AUTH_TOKEN = `Bearer ${token}`

    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common['Accept'] = 'application/json'
    // axios.defaults.withCredentials = true

    try {
      const response = await axios({
        method: data.method,
        url: data.url,
        data: data.body,
      })

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error) {
      if (error.response) {
        console.log(error)
        if (error.response.status === 422) {
          let errors = error.response.data.errors
          let message = ''
          for (const key in errors) {
            message += `${errors[key]}` + "<br>"
          }
          if (message.length > 150) {
            notify.alert.error(message.substring(0, 150) + '...... and more errors.')
          } else {
            notify.alert.error(message)
          }
        } else if (error.response.status === 404) {
          return []
        }

        return {
          status: error.response.status,
          data: error.response.data,
        }
      } else if (error.request) {
        sessionStorage.clear();
        notify.alert.error('Network Error. Server is not responding. Please try again.')
      } else {
        notify.alert.error('Something went wrong. Please try again.')
      }
      console.log(error)
      return []
    }
  },
}
