'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getEmployeeRevenues(id) {
        const data = {
            method: 'get',
            url: 'employee-revenues/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createEmployeeRevenue(json) {
        const data = {
            method: 'post',
            url: 'employee-revenue',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Employee Revenue created successfully')
            return true;
        }
    },
    async updateEmployeeRevenue(json, id) {
        const data = {
            method: 'patch',
            url: 'employee-revenue/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Employee Revenue updated successfully')
            return true;
        }
    },
}
