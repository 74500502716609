function Ksh(number) {
  let Ksh = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'KES',
    minimumFractionDigits: 2
  });
  number = number > 0 ? number : 0
  return Ksh.format(number)
}

export default Ksh