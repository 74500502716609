'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";

export default {
    async getAnalytics(year) {
        const data = {
            method: 'get',
            url: 'analytics/' + year,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        } else if(response.status === 401){
            sessionStorage.clear();
            router.push('/login')
        }
    },
    async getEmployeeAnalytics(employeeId, year) {
        const data = {
            method: 'get',
            url: 'employeeStats/' + employeeId + '/' + year,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getYears() {
        const data = {
            method: 'get',
            url: 'years',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    }
}
