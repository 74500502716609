'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import router from "@/router";

export default {
    async getPayrolls() {
        const data = {
            method: 'get',
            url: 'casual-payrolls',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getPayrollNextNo() {
        const data = {
            method: 'get',
            url: 'casual-payrollNextNo',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getPayroll(id) {
        const data = {
            method: 'get',
            url: 'casual-payroll/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createPayroll(json) {
        const data = {
            method: 'post',
            url: 'casual-payroll',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Payroll created successfully')
            return true;
        }
    },
    async confirmPayroll(id) {
        const data = {
            method: 'patch',
            url: 'casual-payroll/status/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payroll confirmed successfully')
            window.location.reload()
        }
    },
    async deletePayroll(id) {
        const data = {
            method: 'delete',
            url: 'casual-payroll/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Payroll deleted successfully')
            router.push('/payrolls/casuals')
        }
    },
    async deletePayslip(id) {
        const data = {
            method: 'delete',
            url: 'casual-payslip/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Casual payment remittance deleted successfully')
            return true;
        }
    },
}
