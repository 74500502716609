<template>
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-5">
      <Card class="bg-white">
        <div class="modal-body p-1">
          <h2 class="text-center text-uppercase sidebar-brand align-items-center fw-bold logo-text">
            <img src="../../../public/logo.png" width="80px" alt="logo">
          </h2>
          <div class="modal-body p-1">
            <div class="font-size-150 fw-lighter text-center text-uppercase mt-3 mb-4">
              Register Organization
            </div>
          </div>
          <hr class="my-0" />
          <div class="mt-4">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-5">
                  <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Name" v-model="organization.name">
                </div>
                <div class="form-group col-md-7">
                  <label for="name" class="form-label fw-bold h5 mt-3">Address:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Address" v-model="organization.address">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Phone:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Phone" v-model="organization.phone">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Email:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Email" v-model="organization.email">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Website: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="Website" v-model="organization.website">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">PIN No:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="PIN No" v-model="organization.pin_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">SHIF No: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="NHIF No" v-model="organization.nhif_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">NSSF No: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="NSSF No" v-model="organization.nssf_no">
                </div>
              </div>
              <hr class="border-1 border-info border-opacity-75 mt-3" />
              <div class="row">
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Bank Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="bankOptions" id="bankName" v-model="organization.bank_name" placeholder="Type to search...">
                  <datalist id="bankOptions">
                    <option v-for="bank in bankNames.banks" :key="bank" :value="bank"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Branch Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="branchOptions" id="branchName" v-model="organization.branch_name" placeholder="Type to search...">
                  <datalist id="branchOptions">
                    <option v-for="branch in bankBranches" :key="branch" :value="branch"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Account Number: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="Account number" v-model="organization.account_no">
                </div>
              </div>

              <div class="col-md-12 d-flex justify-content-between mt-4">
                <button class="btn btn-secondary text-uppercase" type="submit" @click="logout">Log Out</button>
                <button class="btn btn-dark text-uppercase" type="submit" @click="addOrganization()">Add Organization</button>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>

import organizationService from "@/api/organizationService";
import authService from "@/api/authService";
import bankService from "@/api/bankService";

export default {
  name: "AddOrganization",

  data() {
    return {
      organization: {
        paye: true,
        nhif: true,
        nssf: true,
        housing: true,
        nita: true,
        trial: 2
      },
      bankNames: {
        banks: [],
        branches: {}
      },
      bankBranches: []
    }
  },

  methods: {
    async addOrganization() {
      await organizationService.selfCreateOrganization(this.organization)
    },
    async logout() {
      await authService.userLogout()
    },
  },

  async created() {
    this.bankNames = await bankService.getBankNames()
  },

  watch: {
    'organization.bank_name': function () {
      this.bankBranches = this.bankNames.branches[this.organization.bank_name]
    }
  },
}
</script>

<style scoped>

</style>
