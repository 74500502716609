import App from '@/App.vue'
import { createApp } from 'vue'
import router from '@/router'

// Loading application components and styles
import loader from './plugins/loader';

window.$ = window.jQuery = require('jquery');

// Create vue app
const app = createApp(App)

app.use(loader)
app.use(router)


// Mount vue app
app.mount('#app')

