'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getStations() {
        const data = {
            method: 'get',
            url: 'work-stations',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getStationNextNo() {
        const data = {
            method: 'get',
            url: 'work-stationNextNo',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async createStation(json) {
        const data = {
            method: 'post',
            url: 'work-station',
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 201) {
            notify.alert.success('Station created successfully')
            return true;
        }
    },
    async updateStation(json, id) {
        const data = {
            method: 'patch',
            url: 'work-station/' + id,
            body: json,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            notify.alert.success('Station updated successfully')
            return true;
        }
    },
}
