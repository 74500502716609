<template>
  <div class="row justify-content-center">
    <div class="col-sm-8 col-md-6 col-lg-4 col-xl-3 mt-5">
      <Card class="bg-white">
        <div class="modal-body p-1">
          <h2 class="text-center text-uppercase sidebar-brand align-items-center fw-bold logo-text">
            <img src="../../../public/logo.png" width="80px" alt="logo">
          </h2>
          <div class="modal-body p-1">
            <div class="font-size-150 fw-lighter text-center text-uppercase mt-3 mb-4">
              Verify OTP
            </div>
          </div>
          <hr class="my-0" />
          <div class="mt-4">
            <form @submit.prevent="">
              <UInput placeholder="Enter OTP" type="number" size="lg" autofocus v-model="user.otp">
                <template #label>OTP Code</template>
              </UInput>
              <div class="row">
                <span>
                  <button class="btn btn-sm btn-outline-info text-uppercase otpBtn" @click="resendOTP">Resend OTP</button>
                </span>
              </div>
              <div class="mt-4 d-flex flex-row justify-content-end">
                <button class="btn btn-dark text-uppercase me-3" @click="$router.go(-1)">Cancel</button>
                <button type="button" class="btn btn-info text-uppercase" :disabled="loading" @click="verifySubmit">
                  <span v-if="!loading">Submit</span>
                  <Spinner v-else size="20" stroke="#ffffff" spinner-class="mt-2"/>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>

import authService from "@/api/authService";

export default {
  name: 'VerifyOtp',
  data() {
    return {
      loading: false,
      email: sessionStorage.getItem('email')
    }
  },

  methods: {
    async verifySubmit() {
      this.loading = true
      await authService.verifyOTP(this.user)
      this.loading = false
    },
    async resendOTP() {
      let response = await authService.requestOTP({email: this.email})
      if (response)
        this.otpTimer()
    },
    otpTimer() {
      let otp = $('.otpBtn');
      (function timer() {
        otp.prop('disabled', true);
        let i = Number(sessionStorage.getItem('otp'));
        if (i === 0)
          otp.prop('disabled', false);
        if (--i < 0) return;
        setTimeout(function() {
          sessionStorage.setItem('otp', i.toString())
          otp.html('Resend. Wait, ' + i + ' secs');
          if (i === 0) {
            sessionStorage.removeItem('otp');
            otp.html('Resend OTP');
            otp.prop('disabled', false);
            return;
          }
          timer();
        }, 1000);
      })();
    }
  },

  mounted: function () {
    this.otpTimer()
  },

  computed: {
    user() {
      return {
        email: sessionStorage.getItem('email'),
        otp: process.env.NODE_ENV === 'development' ? '123456' : ''
      }
    }
  },
}
</script>
