import ConfirmationDialog from '@/components/confirmation/ConfirmationDialog';

import { createConfirmDialog } from 'vuejs-confirm-dialog'
const confirmDialog = (message, action, content = null) => {
    let title = "Delete " + message
    let messageContent = "Are you sure you want to delete this " + message
    if (content) {
        title = message
        messageContent = content
    }
    const { reveal, onConfirm } = createConfirmDialog(ConfirmationDialog, { title: title, message: messageContent })

    onConfirm(action)

    reveal()
}

export default confirmDialog