<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Work Stations ({{stations.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-success btn-sm" @click="stationModal = true; stationMode = 'add'">+ Add Work Station</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Created</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="station in stations">
                <td>{{ station.code }}</td>
                <td>{{ station.name }}</td>
                <td>{{ station.description }}</td>
                <td>{{ formatDate(station.created_at) }}</td>
                <td>
                  <button
                      v-if="profile.role !== 'user' && profile.role !== 'admin'"
                      class="btn btn-info btn-sm"
                      @click="stationModal = true; stationMode = 'edit'; populateStationEdit(station)"
                  >
                    Edit
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Station Modal -->
    <Modal :open="stationModal" @closed="stationModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="stationMode === 'add'">Add Work Station</h5>
        <h5 class="modal-title m-0" v-else>Edit Work Station</h5>
        <button @click="stationModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="code" class="form-label fw-bold h5 mt-3">Code:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Station code" v-model="station.code" readonly>
            </div>
            <div class="form-group col-md-12">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" placeholder="Station name" v-model="station.name">
            </div>
            <div class="form-group col-md-12">
              <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" placeholder="Station description" v-model="station.description">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="stationModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="stationMode === 'add'" @click="addStation">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateStation(station.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Station Modal End -->

  </div>
</template>

<script>
import stationService from "@/api/stationService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";

export default {
  name: "WorkStations",

  data(){
    return {
      profile: {},
      loading: true,
      stations: [],
      stationMode: 'add',
      stationModal: false,
      station: {
        code: '',
        name: '',
        description: '',
      },
      nextNo: {},
    }
  },

  methods: {
    formatDate,
    async getStations() {
      this.stations = await stationService.getStations()
      this.nextNo = await stationService.getStationNextNo()
      this.station.code = this.nextNo.next
      this.loading = false
    },
    populateStationEdit(station) {
      this.station = station
    },
    async addStation() {
      let response = await stationService.createStation(this.station)
      if (response)
        this.stationModal = false
        await this.getStations()
    },
    async updateStation(id) {
      let response = await stationService.updateStation(this.station, id)
      if (response)
        this.stationModal = false
        await this.getStations()
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getStations()
    if(this.$route.query.add)
      this.stationModal = true
  },

  watch: {
    stationModal: function () {
      if (!this.stationModal) {
        this.station = {
          code: this.nextNo.next,
        }
      }
    },
  }

}
</script>

<style scoped>

</style>
